import React , { useEffect } from 'react';
import './PrivacyPolicy.css'

const PrivacyPolicyAliviusGo = () => {
    useEffect(() => {
                    window.scrollTo(0, 0); // Scroll to the top of the page
                  }, []);
    return (
        <div class="pp-container">
        <p style={{textAlign: 'center', marginBottom: '1rem'}}><b class="bold-underline">POL&Iacute;TICA DE PRIVACIDADE DO ALIVIUS GO </b></p>
        <p><span class="bold">1. Introdu&ccedil;&atilde;o</span> <br />
            A SVAAS Wellness Private Limited (&ldquo;SVAAS&rdquo;), uma empresa existente e registrada de acordo com as
            leis da &Iacute;ndia, com sede em 8-2-337, Road No. 3, Banjara Hills, Hyderabad- 500 034, Telangana,
            &Iacute;ndia, e cada uma de suas afiliadas e entidades subsidi&aacute;rias (coletivamente
            &ldquo;SVASS&rdquo;) est&atilde;o comprometidas em manter a precis&atilde;o, confidencialidade e
            seguran&ccedil;a dos dados pessoais dos usu&aacute;rios do aplicativo Alivius GO (doravante
            &ldquo;Aplicativo&rdquo;). Esta Pol&iacute;tica de Privacidade do Alivius GO (&ldquo;Pol&iacute;tica&rdquo;)
            foi preparada para explicar aos usu&aacute;rios do Aplicativo quais dados pessoais s&atilde;o coletados e
            como s&atilde;o coletados, tratados e divulgados, assim como outras informa&ccedil;&otilde;es relacionadas
            aos direitos destes usu&aacute;rios, em conex&atilde;o com o uso do Aplicativo. <br />
            <br />
            Antes de registrar sua conta no Aplicativo, certifique-se de que leu, entendeu e aceitou todas as
            informa&ccedil;&otilde;es desta Pol&iacute;tica. Caso voc&ecirc; n&atilde;o concorde com qualquer parte
            desta Pol&iacute;tica, n&atilde;o registre uma Conta (conforme defini&ccedil;&atilde;o abaixo), nem acesse o
            Aplicativo. A conclus&atilde;o do registro da sua Conta ou a continua&ccedil;&atilde;o do uso do Aplicativo
            indicar&aacute; sua aceita&ccedil;&atilde;o dos termos desta Pol&iacute;tica. Devido ao fato de que nossa
            Pol&iacute;tica pode mudar a qualquer momento, recomendamos que voc&ecirc; revise esta Pol&iacute;tica de
            tempos em tempos para verificar se h&aacute; atualiza&ccedil;&otilde;es. <br />
            <br />
            Esta Pol&iacute;tica de Privacidade ser&aacute; aplic&aacute;vel aos Usu&aacute;rios do Alivius GO. Ao
            acessar o Alivius Go ou utilizar qualquer um dos servi&ccedil;os nele inclu&iacute;dos, o usu&aacute;rio
            reconhece que leu e compreendeu os termos e condi&ccedil;&otilde;es de uso, que incluem esta Pol&iacute;tica
            de Privacidade, e concorda com os termos.
        </p>
        <p><span class="bold">2. Defini&ccedil;&otilde;es </span><br />
            <span class="bold">&ldquo;Conta&rdquo;:</span> Registro de conta que permite ao Usu&aacute;rio acessar e
            usar o Aplicativo. <br />
            <span class="bold">&ldquo;Dados Pessoais&rdquo;:</span> Significa qualquer informa&ccedil;&atilde;o de
            identifica&ccedil;&atilde;o
            relacionada a um Usu&aacute;rio do Aplicativo, incluindo, mas n&atilde;o se limitando, nome, telefone,
            e-mail e data de nascimento; <span class="bold">&ldquo;Pol&iacute;tica&rdquo;</span>: refere-se a esta
            Pol&iacute;tica de
            Privacidade
            do Alivius GO. <br />
            <span class="bold">&ldquo;Usu&aacute;rio&rdquo; ou &ldquo;Voc&ecirc;&rdquo;</span>: significa qualquer
            pessoa f&iacute;sica que
            fa&ccedil;a uso do Aplicativo, a qualquer t&iacute;tulo, em especial, os profissionais de sa&uacute;de
            autorizados, enfermeiras e prestadores de cuidados das entidades dedicadas aos servi&ccedil;os de
            sa&uacute;de. <br />
            <span class="bold">Aplicativo:</span> Solu&ccedil;&atilde;o de software com base na Internet,
            compat&iacute;vel com os sistemas
            operacionais Android e iOS, fornecida via aplicativo m&oacute;vel ao Usu&aacute;rio, como um aplicativo
            de
            propriedade da SVAAS, usado para melhorar a comunica&ccedil;&atilde;o entre as entidades dedicadas aos
            servi&ccedil;os de sa&uacute;de e seus pacientes.
        </p>
        <p><span class="bold">3. Legisla&ccedil;&atilde;o aplic&aacute;vel </span><br />
            Aplica-se &agrave; presente Pol&iacute;tica a legisla&ccedil;&atilde;o brasileira, incluindo, mas n&atilde;o
            se limitando: (i) as Leis Federais n&ordm; 13.709/2018, conforme alterada pela Lei n&ordm; 13.853/2019, e
            n&ordm; 12.965/2014 (&ldquo;doravante LGPD&rdquo;); (ii) o Decreto n&ordm; 8.771/2016; (iii) a
            Constitui&ccedil;&atilde;o Federal Brasileira; (iv) o C&oacute;digo Civil Brasileiro, conforme alterado de
            tempos em tempos; e qualquer futura legisla&ccedil;&atilde;o brasileira ou regulamenta&ccedil;&atilde;o
            aplic&aacute;vel emitida pela Autoridade Brasileira de Prote&ccedil;&atilde;o de Dados (&ldquo;ANPD&rdquo;)
            e/ou pela Ag&ecirc;ncia Nacional de Vigil&acirc;ncia Sanit&aacute;ria (&ldquo;ANVISA&rdquo;), ou ainda outra
            autoridade governamental competente. </p>
        <p><span class="bold">4. Princ&iacute;pios </span><br />
            O tratamento dos Dados Pessoais fornecidos pelo Usu&aacute;rio no &acirc;mbito da utiliza&ccedil;&atilde;o
            do Aplicativo, ser&aacute; baseado, dentre outros, nos princ&iacute;pios da <u>finalidade, essencialidade e
                transpar&ecirc;ncia. </u><br />
            <br />
            A SVAAS est&aacute; comprometida em proteger a privacidade dos Usu&aacute;rios e, neste sentido, toma e as
            medidas razo&aacute;veis para proteger a privacidade e a confidencialidade dos Dados Pessoais do
            Usu&aacute;rio, conforme fornecidos por estes, no &acirc;mbito da utiliza&ccedil;&atilde;o do Aplicativo.
        </p>
        <p><span class="bold">5. Tipos de Dados Pessoais Coletados </span><br />
            N&oacute;s coletamos os Dados Pessoais que o Usu&aacute;rio fornecer voluntariamente por meio do Aplicativo,
            incluindo: <br />
            <br />
            &bull; Dados de identifica&ccedil;&atilde;o, como nome, idade, sexo, n&uacute;mero de telefone,
            endere&ccedil;o de e-mail, designa&ccedil;&atilde;o do Usu&aacute;rio junto ao Administrador, conforme
            definido nos Termos e Condi&ccedil;&otilde;es de Uso de Alivius GO, e conforme aplic&aacute;vel,
            n&uacute;mero de registro/inscri&ccedil;&atilde;o do Usu&aacute;rio junto ao seu respectivo
            &oacute;rg&atilde;o de classe. <br />
            &bull; Dados do Paciente (conforme definido nos Termos e Condi&ccedil;&otilde;es de Uso de Alivius Go)
            relacionados &agrave;s informa&ccedil;&otilde;es do Paciente, fornecidos pelos Pacientes em outro aplicativo
            da SVAAS, denominado Alivius, e acessados pelo Usu&aacute;rio no Alivius GO. <br />
            &bull; Dados relacionados ao registro de som e imagem em conex&atilde;o com os servi&ccedil;os de
            sa&uacute;de que est&atilde;o sendo prestados ao Paciente com a participa&ccedil;&atilde;o do
            Usu&aacute;rio. <br />
            &bull; Dados Pessoais relacionados &agrave; atividade de navega&ccedil;&atilde;o do Usu&aacute;rio durante o
            uso do Aplicativo, incluindo, conforme aplic&aacute;veis, endere&ccedil;o IP, cookies, dura&ccedil;&atilde;o
            do uso, data e hora de acesso, idioma utilizado, localiza&ccedil;&atilde;o geogr&aacute;fica. <br />
            &bull; Outros Dados Pessoais e informa&ccedil;&otilde;es fornecidas pelo Usu&aacute;rio na
            utiliza&ccedil;&atilde;o das funcionalidades do Aplicativo e quaisquer outras informa&ccedil;&otilde;es
            fornecidas pelo Usu&aacute;rio que n&atilde;o revelem sua identidade espec&iacute;fica. <br />
            <br />
            Devido ao fato de que o Aplicativo processa apenas dados selecionados pelo Usu&aacute;rio, a SVAAS
            n&atilde;o atua isoladamente como controladora dos Dados Pessoais processados por meio do Aplicativo. A
            Administradora (conforme definido nos Termos e Condi&ccedil;&otilde;es do Aplicativo) tamb&eacute;m
            atuar&aacute; conjuntamente como Controlador de Dados das informa&ccedil;&otilde;es processadas por meio do
            Aplicativo por seu Usu&aacute;rio. <br />
            <br />
            Portanto, o Usu&aacute;rio, no &acirc;mbito do Aplicativo, assume a condi&ccedil;&atilde;o de controlador,
            tendo autonomia em rela&ccedil;&atilde;o ao tratamento de Dados Pessoais e Dados Pessoais Sens&iacute;veis,
            podendo escolher a finalidade para a qual s&atilde;o tratados, a categoria de dados a serem coletados, por
            quanto tempo tais dados s&atilde;o retidos, e outras quest&otilde;es diretamente relacionadas ao tratamento
            de Dados Pessoais e Dados Pessoais Sens&iacute;veis. <br />
            <br />
            Observe que os dados coletados e processados pelo nosso Aplicativo s&atilde;o controlados pela SVVAS e pela
            Administradora, que os usam, divulgam e protegem, de acordo com suas respectivas pol&iacute;ticas de
            privacidade e prote&ccedil;&atilde;o de dados. <br />
            <br />
            N&atilde;o obstante, a SVAAS ser&aacute; controladora de dados apenas para os fins de: (i) hospedar o
            Aplicativo em servidores externos; (ii) prestar servi&ccedil;os de suporte e manuten&ccedil;&atilde;o aos
            Usu&aacute;rios; (iii) abordar problemas t&eacute;cnicos, bugs e falhas no Aplicativo; (iv) identificar
            oportunidades para aprimorar recursos e otimizar a experi&ecirc;ncia do Usu&aacute;rio; (v) adaptar
            recursos, interfaces e funcionalidades de forma personalizada; e (vi) analisar tend&ecirc;ncias de uso para
            planejar a escalabilidade. <br />
            <br />
            Como processador de dados, embora a SVVAS n&atilde;o controle os dados que s&atilde;o coletados diretamente
            do Usu&aacute;rio, a SVVAS adota v&aacute;rias pr&aacute;ticas de seguran&ccedil;a para garantir que as
            informa&ccedil;&otilde;es coletadas em nosso Aplicativo sejam processadas de maneira segura de acordo com a
            legisla&ccedil;&atilde;o aplic&aacute;vel. <br />
            <br />
            Para saber mais sobre como coletamos cookies durante seu acesso ao nosso Aplicativo consulte nossa [&#9679;]
        </p>
        <p><span class="bold">6. Quando coletamos os seus Dados Pessoais </span><br />
            &bull; Ap&oacute;s a cria&ccedil;&atilde;o de uma conta pelo Usu&aacute;rio; <br />
            &bull; Quando o Usu&aacute;rio carrega quaisquer dados diretamente no Aplicativo; <br />
            &bull; Enquanto o Usu&aacute;rio estiver usando o Aplicativo e/ou os produtos e alguns dos servi&ccedil;os
            disponibilizados no Aplicativo; <br />
            &bull; Quando obtemos informa&ccedil;&otilde;es sobre seu uso do Aplicativo; <br />
            &bull; Quando o Usu&aacute;rio usar o chat dispon&iacute;vel no Aplicativo; <br />
            &bull; Quando o Usu&aacute;rio se cadastrar para receber e-mails, alertas ou outras
            informa&ccedil;&otilde;es; e <br />
            &bull; Quando recebemos informa&ccedil;&otilde;es de terceiros. <br />
            <br />
            O Usu&aacute;rio que preencher Dados Pessoais no Aplicativo, conforme o caso, &eacute; exclusivamente
            respons&aacute;vel pela precis&atilde;o, clareza, relev&acirc;ncia, atualiza&ccedil;&atilde;o e integridade
            dos Dados Pessoais fornecidos. <br />
            <br />
            As informa&ccedil;&otilde;es enviadas devem ser mantidas atualizadas para garantir que os servi&ccedil;os
            permane&ccedil;am relevantes e cheguem at&eacute; Voc&ecirc;.
        </p>
        <p><span class="bold">7. Uso das informa&ccedil;&otilde;es </span><br />
            Ao usar este Aplicativo e/ou nossos servi&ccedil;os, Voc&ecirc; reconhece que nos autorizou a usar seus
            Dados Pessoais para os seguintes fins: <br />
            <br />
        <table>
            <tr>
                <th>Objetivos</th>
                <th>Base Legal</th>
            </tr>
            <tr>
                <td>Prestar serviços e fornecimento do Aplicativo</td>
                <td>cumprimento de obrigações legais e a execução de um contrato ou procedimentos preliminares</td>
            </tr>
            <tr>
                <td>Para melhorar nosso Aplicativo e nossos serviços de suporte e manutenção para nossos Clientes e para
                    os Usuários do Aplicativo</td>
                <td>cumprimento contratual, o exercício regular de direitos em processo judicial ou administrativo, o
                    interesse legítimo e cumprimento de obrigações legais, conforme aplicável</td>
            </tr>
            <tr>
                <td>Mensagens de contato e marketing</td>
                <td>interesse legítimo em informar o titular dos Dados Pessoais sobre seus temas de interesse, bem como
                    sobre as atividades oferecidas pelo Aplicativo</td>
            </tr>
            <tr>
                <td>Responder a solicitações, reclamações ou prestar esclarecimentos aos titulares dos Dados Pessoais
                </td>
                <td>interesse legítimo, cumprimento de obrigações legais e cumprimento contratual, conforme aplicável
                </td>
            </tr>
            <tr>
                <td>Realizar análises estatísticas, garantindo, sempre que possível, a anonimização dos Dados Pessoais
                </td>
                <td>interesse legítimo da SVAAS e/ou de seu Cliente</td>
            </tr>
            <tr>
                <td>Medição do nível de satisfação dos Usuários do Aplicativo</td>
                <td>Consentimento por escrito</td>
            </tr>
        </table>
        <br />
        O Usu&aacute;rio pode, a qualquer momento, optar por n&atilde;o receber mensagens e tipos de
        comunica&ccedil;&otilde;es relacionadas a marketing, escrevendo para n&oacute;s por meio do e-mail
        [<span class="highlight">support@myalivius.com</span>]. <br />
        <br />
        Visando a melhoria cont&iacute;nua do Aplicativo, podemos ocasionalmente acionar determinados atributos no
        Aplicativo controlados por nossos clientes, que coletam e armazenam informa&ccedil;&otilde;es de uso no
        sistema, de modo a analisar a intera&ccedil;&atilde;o do Usu&aacute;rio com as ferramentas e corrigir
        eventuais erros ou bugs. <br />
        <br />
        Utilizaremos os seus Dados Pessoais apenas para os fins para os quais foram coletados. Qualquer
        altera&ccedil;&atilde;o na finalidade do tratamento dos Dados Pessoais ou Transfer&ecirc;ncia Internacional
        de Dados Pessoais ser&aacute; informada ao respectivo titular destes, ainda que para fins de cumprimento de
        obriga&ccedil;&otilde;es legais e/ou regulat&oacute;rias impostas sobre a SVAAS ou para o cumprimento de
        seus interesses leg&iacute;timos.
        </p>
        <p><span class="bold">8. Crian&ccedil;as </span><br />
            <br />
            A SVAAS n&atilde;o pretende coletar Dados Pessoais de crian&ccedil;as e adolescentes.
        </p>
        <p><span class="bold">9. Compartilhamento de Dados Pessoais </span><br />
            Comprometemo-nos a n&atilde;o divulgar, salvo disposi&ccedil;&atilde;o em contr&aacute;rio nesta
            Pol&iacute;tica de Privacidade, os Dados Pessoais fornecidos por Voc&ecirc; a qualquer pessoa, a menos que
            seja previsto nos seguintes casos: <br />
            <br />
            &bull; Com as afiliadas da SVAAS e demais empresas do seu grupo, com parceiros de neg&oacute;cios, agentes e
            subcontratados localizados no Brasil ou no exterior, tudo em conson&acirc;ncia com a presente
            Pol&iacute;tica e a legisla&ccedil;&atilde;o aplic&aacute;vel para fins de cumprimento de
            obriga&ccedil;&otilde;es legais e regulat&oacute;rias impostas sobre a SVAAS e para o cumprimento de seu
            interesse leg&iacute;timo, quando atuando como controladora de dados. <br />
            &bull; Com funcion&aacute;rios e parceiros de neg&oacute;cios (terceiros e subcontratados) para o
            processamento dos Dados Pessoais do Usu&aacute;rio para auxiliar na execu&ccedil;&atilde;o do Aplicativo e
            fornecer nossos servi&ccedil;os, incluindo, por exemplo, provedores de hospedagem na web e provedores de TI
            (AWS, T Mobile etc.), para desenvolvimento de sistemas e suporte t&eacute;cnico, com auditores externos,
            consultores administrativos e jur&iacute;dicos para outros servi&ccedil;os. <br />
            &bull; Investigar, prevenir ou agir em rela&ccedil;&atilde;o a atividades ilegais, suspeita de fraude,
            situa&ccedil;&otilde;es relacionadas a potenciais amea&ccedil;as a qualquer seguran&ccedil;a individual.
            <br />
            &bull; Em caso de fus&atilde;o, aquisi&ccedil;&atilde;o ou transfer&ecirc;ncia de todos ou parte
            significativa dos ativos da SVAAS. <br />
            &bull; Mediante autoriza&ccedil;&atilde;o por escrito dos Usu&aacute;rios, conforme venha a ser exigido pela
            legisla&ccedil;&atilde;o aplic&aacute;vel. <br />
            &bull; Cumprimento de obriga&ccedil;&otilde;es legais, judiciais ou regulat&oacute;rias (conforme exigido
            por qualquer autoridade competente, incluindo a Ag&ecirc;ncia Nacional de Sa&uacute;de
            (&ldquo;ANVISA&rdquo;) ou Food Drugs e FDA; <br />
            &bull; Proteger os direitos, a seguran&ccedil;a e a propriedade da SVAAS na medida permitida por lei. <br />
            <br />
            Definimos responsabilidades e processos para avaliar e responder a solicita&ccedil;&otilde;es de dados de
            autoridades policiais ou Governamentais. As requisi&ccedil;&otilde;es ser&atilde;o feitas de acordo com as
            regras aplic&aacute;veis. <br />
            <br />
            Os prestadores de servi&ccedil;os terceirizados s&atilde;o obrigados a usar medidas de seguran&ccedil;a
            apropriadas para proteger a confidencialidade e a seguran&ccedil;a das Informa&ccedil;&otilde;es Pessoais.
            <br />
            <br />
            Na hip&oacute;tese de a SVAAS compartilhar Dados Pessoais com terceiros localizados fora do Brasil,
            dever&aacute; garantir que essa transfer&ecirc;ncia internacional de Dados ser&aacute; realizada de acordo
            com o disposto na legisla&ccedil;&atilde;o aplic&aacute;vel, de forma adequada, aderente &agrave;s suas
            finalidades e dentro dos limites do que for efetivamente necess&aacute;rio para atender a esses requisitos.
            <br />
            <br />
            A SVAAS tamb&eacute;m pode compartilhar seus Dados Pessoais agregados com os dados de outras pessoas, bem
            como os dados agregados que n&atilde;o incluem informa&ccedil;&otilde;es de identifica&ccedil;&atilde;o
            pessoal ou s&atilde;o anonimizados. <br />
            <br />
            Informamos que a SVAAS pode transferir os seus Dados Pessoais para jurisdi&ccedil;&otilde;es estrangeiras
            com leis de prote&ccedil;&atilde;o de dados menos rigorosas do que as leis do seu pa&iacute;s. <br />
            <br />
            N&atilde;o venderemos seus Dados Pessoais a terceiros.
        </p>
        <p><span class="bold">10. Comunica&ccedil;&atilde;o </span><br />
            A SVAAS e a Administradora se comunicar&atilde;o com Voc&ecirc; (por e-mail, servi&ccedil;o postal, correio,
            servi&ccedil;os de mensagens m&oacute;veis e ramais de telefone ou redes sociais) &agrave; medida em que
            voc&ecirc; concordar em receber essa comunica&ccedil;&atilde;o ou quando os requisitos operacionais ou
            regulamentares exigirem que o fa&ccedil;amos. <br />
            <br />
            Podemos ainda nos comunicar com Voc&ecirc; por meio dos nossos fornecedores terceirizados. <br />
            <br />
            Voc&ecirc; ter&aacute; a op&ccedil;&atilde;o de cancelar a inscri&ccedil;&atilde;o para receber essa
            comunica&ccedil;&atilde;o por e-mail, enviando sua solicita&ccedil;&atilde;o a n&oacute;s pelo e-mail
            [<span class="highlight">support@myalivius.com</span>.
        </p>
        <p><span class="bold">11. Links / Ofertas de Terceiros</span><br />
            A SVAAS pode permitir que terceiros ofere&ccedil;am Servi&ccedil;os baseados em assinatura e/ou registro
            atrav&eacute;s do Aplicativo ou outras plataformas (por exemplo, WhatsApp, Twitter etc.). A SVAAS n&atilde;o
            ser&aacute; respons&aacute;vel por quaisquer a&ccedil;&otilde;es ou pol&iacute;ticas de tais terceiros e os
            Usu&aacute;rios ser&atilde;o solicitados a verificar a pol&iacute;tica de privacidade aplic&aacute;vel e
            familiarizar-se com os termos e condi&ccedil;&otilde;es de uso de tais prestadores de servi&ccedil;os, ao
            fornecer informa&ccedil;&otilde;es pessoalmente identific&aacute;veis. <br />
            <br />
            A SVAAS n&atilde;o ser&aacute; respons&aacute;vel por qualquer varia&ccedil;&atilde;o nas
            caracter&iacute;sticas/benef&iacute;cios oferecidos por tais terceiros e n&atilde;o assumir&aacute;, em
            nenhum caso, garantia sobre a efici&ecirc;ncia ou efic&aacute;cia de tais ofertas.
        </p>
        <p><span class="bold">12. Reten&ccedil;&atilde;o dos Dados e exclus&atilde;o de dados</span><br />
            Usamos centros de dados e servi&ccedil;os de nuvem de terceiros para armazenar seus dados com
            seguran&ccedil;a e minimizar a perda de dados. As salvaguardas de seguran&ccedil;a e cl&aacute;usulas
            contratuais relevantes, conforme exigido pela legisla&ccedil;&atilde;o aplic&aacute;vel em cada
            jurisdi&ccedil;&atilde;o, s&atilde;o cumpridas durante o armazenamento dos seus dados. <br />
            <br />
            Manteremos apenas os Dados Pessoais e/ou Dados Pessoais Sens&iacute;veis necess&aacute;rios para gerenciar
            os servi&ccedil;os que prestamos e para os fins comerciais leg&iacute;timos e indispens&aacute;veis, pelo
            tempo que for razoavelmente necess&aacute;rio ou permitido para cumprir a finalidade para a qual tais
            informa&ccedil;&otilde;es foram coletadas ou pelo tempo necess&aacute;rio para cumprir os regulamentos e a
            legisla&ccedil;&atilde;o aplic&aacute;vel. <br />
            <br />
            Ao receber uma solicita&ccedil;&atilde;o de desativa&ccedil;&atilde;o ou acionar a desativa&ccedil;&atilde;o
            sua ou por qualquer um dos motivos listados abaixo, excluiremos permanentemente ou anonimizaremos os dados
            associados dentro de noventa (90) dias a partir da data de desativa&ccedil;&atilde;o, em conformidade com as
            leis e regulamentos de prote&ccedil;&atilde;o de dados aplic&aacute;veis: <br />
            1. Desativa&ccedil;&atilde;o iniciada pelo usu&aacute;rio: O usu&aacute;rio do hospital solicitou a
            desativa&ccedil;&atilde;o atrav&eacute;s do administrador do hospital. <br />
            2. Desativa&ccedil;&atilde;o Administrativa: O administrador do hospital desativou a conta do usu&aacute;rio
            por qualquer motivo. <br />
            3. Inatividade do Usu&aacute;rio: A conta do usu&aacute;rio esteve inativa, sem transa&ccedil;&otilde;es,
            por um per&iacute;odo de seis <br />
            (6) meses consecutivos. <br />
            4. Rescis&atilde;o do Contrato: Desativa&ccedil;&atilde;o por rescis&atilde;o do contrato entre Svaas e o
            hospital. <br />
            Durante o per&iacute;odo de 90 dias, os dados poder&atilde;o ser conservados apenas para efeitos de
            cumprimento de quaisquer obriga&ccedil;&otilde;es, auditorias ou requisitos legais pendentes, ap&oacute;s o
            qual ser&atilde;o eliminados permanentemente ou anonimizados, salvo disposi&ccedil;&atilde;o legal em
            contr&aacute;rio.
        </p>
        <p><span class="bold">13. Altera&ccedil;&otilde;es &agrave; Pol&iacute;tica de Privacidade </span><br />
            Esta Pol&iacute;tica de Privacidade pode ser alterada, modificada, substitu&iacute;da por uma nova
            pol&iacute;tica, a qualquer momento, a nosso exclusivo crit&eacute;rio. A Pol&iacute;tica de Privacidade
            revisada/suas altera&ccedil;&otilde;es entrar&atilde;o em vigor a partir da data indicada. <br />
            <br />
            Os Usu&aacute;rios s&atilde;o solicitados a verificar periodicamente os termos e condi&ccedil;&otilde;es
            desta Pol&iacute;tica de Privacidade. A SVAAS n&atilde;o ser&aacute;, em nenhuma circunst&acirc;ncia,
            responsabilizados por tais lapsos por parte do Usu&aacute;rio. <br />
            <br />
        </p>
        <p><span class="bold">14. Seguran&ccedil;a da Informa&ccedil;&atilde;o </span><br />
            Tomamos precau&ccedil;&otilde;es razo&aacute;veis para proteger os dados processados no Aplicativo contra
            acesso n&atilde;o autorizado ou altera&ccedil;&atilde;o, divulga&ccedil;&atilde;o ou
            destrui&ccedil;&atilde;o n&atilde;o autorizada das informa&ccedil;&otilde;es que possu&iacute;mos. <br />
            <br />
            &bull; Criptografamos muitos de nossos Servi&ccedil;os usando os certificados SSL. <br />
            &bull; Fornecemos autentica&ccedil;&atilde;o com base em senha para Usu&aacute;rios que fazem login em nosso
            Aplicativo. <br />
            &bull; Analisamos as nossas pr&aacute;ticas de coleta, armazenamento e processamento de
            informa&ccedil;&otilde;es, para nos protegermos contra o acesso n&atilde;o autorizado a sistemas. <br />
            &bull; Permitimos o acesso a informa&ccedil;&otilde;es pessoalmente identific&aacute;veis aos nossos
            funcion&aacute;rios, contratados e agentes, apenas com base na efetiva necessidade e para fins
            leg&iacute;timos, que est&atilde;o sujeitos a rigorosas obriga&ccedil;&otilde;es contratuais de
            confidencialidade para fins de processamento de dados. <br />
            &bull; Realizamos avalia&ccedil;&otilde;es de vulnerabilidade do nosso Aplicativo e sistemas de
            processamento de dados periodicamente. <br />
            <br />
        </p>
        <p><span class="bold">15. Notifica&ccedil;&atilde;o e seguran&ccedil;a de viola&ccedil;&atilde;o de dados
            </span><br />
            Como SVAAS Wellness tem a obriga&ccedil;&atilde;o de reportar incidentes de seguran&ccedil;a de dados
            pessoais &agrave; Autoridade Brasileira de Prote&ccedil;&atilde;o de Dados de acordo com as leis e
            regulamentos aplic&aacute;veis. Voc&ecirc; &eacute; obrigado a relatar qualquer suspeita de
            viola&ccedil;&atilde;o de dados ou incidente de seguran&ccedil;a dentro de um (1) dia ap&oacute;s sua
            ocorr&ecirc;ncia &agrave; SVAAS Wellness Limited. A notifica&ccedil;&atilde;o oportuna nos d&aacute;
            tempo
            suficiente para investigar, implementar a&ccedil;&otilde;es corretivas e cumprir quaisquer
            obriga&ccedil;&otilde;es regulat&oacute;rias de relat&oacute;rios. <br />
            <br />
        </p>
        <p><span class="bold">16. Aviso </span><br />
            As informa&ccedil;&otilde;es, o conte&uacute;do e os materiais fornecidos no Aplicativo s&atilde;o &quot;no
            estado em que se encontram&quot; e a SVAAS n&atilde;o faz nenhuma declara&ccedil;&atilde;o ou garantia
            expressa ou impl&iacute;cita em rela&ccedil;&atilde;o a sua precis&atilde;o, adequa&ccedil;&atilde;o ou
            integridade. <br />
            <br />
        </p>
        <p> <span class="bold">17. Encarregado de Prote&ccedil;&atilde;o de Dados de Informa&ccedil;&otilde;es de
                Contato
                (&quot;DPO&quot;)
            </span><br />
            Caso voc&ecirc; precise de mais esclarecimentos sobre o conte&uacute;do desta Pol&iacute;tica de Privacidade
            ou sobre o uso de seus Dados Pessoais, entre em contato com o DPO da SVAAS por meio do e-mail
            <u>dataprivacy@svaaswellness.com</u> <br />
            <br />
        </p>
        <p><span class="bold">18. Direitos dos Titulares dos Dados </span><br />
            Os titulares dos dados t&ecirc;m o direito de, a qualquer momento, exercer os direitos concedidos pela
            legisla&ccedil;&atilde;o aplic&aacute;vel, incluindo, solicitar o acesso, atualizar e retificar os seus
            dados, o direito de revogar o seu consentimento ou solicitar a elimina&ccedil;&atilde;o dos seus dados,
            solicitar prova do consentimento dado, solicitar a portabilidade dos seus pr&oacute;prios dados para outro
            controlador e o direito de apresentar reivindica&ccedil;&otilde;es perante a autoridade de
            prote&ccedil;&atilde;o de dados. <br />
            <br />

            Caso o exerc&iacute;cio de quaisquer direitos do Usu&aacute;rio resulte em descumprimento das
            obriga&ccedil;&otilde;es da SVAAS, nos termos das leis, c&oacute;digos de conduta, posturas ou regras
            aplic&aacute;veis, a SVAAS n&atilde;o atender&aacute; &agrave; solicita&ccedil;&atilde;o do Usu&aacute;rio.
            Em qualquer caso, &eacute; garantido ao Usu&aacute;rio o pedido de bloqueio dos seus Dados Pessoais para
            posterior tratamento. <br />
            <br />
        </p>
        <p><span class="bold">19. Procedimento para Acessar seus Dados Pessoais </span><br />
            Voc&ecirc; tem acesso gratuito aos seus Dados Pessoais armazenados nas nossas bases de dados, ao tratamento
            a que est&atilde;o sujeitos e &agrave;s finalidades pretendidas, al&eacute;m das indicadas abaixo: <br />
            &bull; As solicita&ccedil;&otilde;es devem ser enviadas por escrito e por e-mail ao DPO. <br />
            &bull; O pedido ser&aacute; analisado para verificar a sua identidade. Se a solicita&ccedil;&atilde;o for
            enviada por algu&eacute;m que n&atilde;o seja Voc&ecirc; (o titular dos dados) e a pessoa n&atilde;o puder
            fornecer evid&ecirc;ncias que estejam agindo legitimamente em seu nome, como seu cuidador ou
            respons&aacute;vel legal, a solicita&ccedil;&atilde;o ser&aacute; rejeitada. <br />
            <br />
            Voc&ecirc; pode apresentar uma reivindica&ccedil;&atilde;o quando considerar que as
            informa&ccedil;&otilde;es inclu&iacute;das nas nossas bases de dados devem ser retificadas, atualizadas ou
            eliminadas, ou quando avisar previamente da alegada viola&ccedil;&atilde;o dos seus direitos, escrevendo
            para n&oacute;s por meio do e-mail [.]. <br />
            <br />
            Garantimos que forneceremos a voc&ecirc; nossa primeira resposta &agrave; sua reivindica&ccedil;&atilde;o no
            prazo de 30 (trinta) dias a partir da data de tal reivindica&ccedil;&atilde;o e garantimos que encerraremos
            sua solicita&ccedil;&atilde;o em ou antes de 90 (noventa) dias a partir da data de recebimento de tal
            reivindica&ccedil;&atilde;o. <br />
            <br />
            Esta Pol&iacute;tica de Privacidade foi atualizada pela &uacute;ltima vez em [<span
                class="highlight">16/10/2024</span>]. Data de
            Vig&ecirc;ncia: [<span class="highlight">16/10/2024</span>]. <br />
        </p>

    </div>

    )
}

export default PrivacyPolicyAliviusGo