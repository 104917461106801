import './Tnc.css'
import  { useEffect }   from 'react'
const TermsAndConditions = () => {
        useEffect(() => {
                        window.scrollTo(0, 0); // Scroll to the top of the page
                      }, []);
    return (
        <div class="container-div">
        <h2 class="center"><span class="bold">TERMOS E CONDI&Ccedil;&Otilde;ES DE
                USO DO APLICATIVO ALIVIUS&nbsp;</span></h2>
        <p class="center"><span>&nbsp;</span></p>
        <p><span>Leia atentamente estes termos e condi&ccedil;&otilde;es de
                uso (&ldquo;<span>Termos e
                    Condi&ccedil;&otilde;es&rdquo;</span>) antes de usar o aplicativo Alivius
                (&ldquo;<span>Aplicativo</span>&rdquo;). Estes Termos e
                Condi&ccedil;&otilde;es estabelecem as condi&ccedil;&otilde;es legais e obrigat&oacute;rias que
                voc&ecirc;, como Usu&aacute;rio do Aplicativo (conforme definido abaixo), concorda livre e
                voluntariamente em cumprir.</span><span>&nbsp;</span></p>
        <p><span>A Pol&iacute;tica de Processamento de
                Informa&ccedil;&otilde;es (&ldquo;<span>PPI</span>&rdquo;)
                do Operador da Plataforma (<em>conforme definido abaixo</em>) &eacute; parte integrante destes Termos e
                Condi&ccedil;&otilde;es. Portanto, sempre que, neste texto, for feita refer&ecirc;ncia aos Termos e
                Condi&ccedil;&otilde;es, tamb&eacute;m ser&aacute; feita refer&ecirc;ncia &agrave; PPI, que pode ser
                acessada neste link: <a
                    href="https://www.myalivius.com/privacy-policy">https://www.myalivius.com/privacy-policy</a></span><span>&nbsp;</span>
        </p>
        <p><span>Os termos em mai&uacute;sculas usados neste documento e
                n&atilde;o definidos de outra forma ter&atilde;o o significado estabelecido entre
                aspas.</span><span>&nbsp;</span></p>
        <h4><span>1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span
                    class="underline">Administra&ccedil;&atilde;o do
                    aplicativo</span></span></h4>
        <p><span>O acesso ao Aplicativo &eacute; fornecido ao Usu&aacute;rio
                mediante solicita&ccedil;&atilde;o do Usu&aacute;rio e/ou uso recomendado dos servi&ccedil;os de um
                patrocinador terceirizado, como o Prestador de Servi&ccedil;os de Sa&uacute;de do Usu&aacute;rio (Pessoa
                F&iacute;sica ou Jur&iacute;dica) (Administrador). O Usu&aacute;rio concorda que o uso dos
                servi&ccedil;os do Aplicativo n&atilde;o &eacute; uma condi&ccedil;&atilde;o para entrar ou receber
                qualquer tratamento m&eacute;dico do Administrador.&nbsp;</span></p>
        <p class="align-left"><span>&nbsp;</span><span>Este Aplicativo &eacute;
                administrado pelo Administrador de acordo com as leis da
                Rep&uacute;blica do Brasil, com experi&ecirc;ncia e licen&ccedil;a para fornecer servi&ccedil;os
                m&eacute;dicos, de sa&uacute;de e afins, que incluem, mas n&atilde;o se limitam a atendimento hospitalar
                e ambulatorial, diagn&oacute;sticos, servi&ccedil;os terap&ecirc;uticos, servi&ccedil;os laboratoriais,
                medicamentos, cuidados de enfermagem oncol&oacute;gica, entre outros
                (&ldquo;</span><span>Administrador</span><span>&rdquo;).&nbsp;</span><span>&nbsp;</span>
        </p>
        <p><span><span>O
                    Administrador assumir&aacute; total responsabilidade por todo o Conte&uacute;do (conforme definido
                    abaixo) e pelas informa&ccedil;&otilde;es</span> <span>transportadas e
                    incorporadas nas e por meio das Fun&ccedil;&otilde;es (conforme
                    definido abaixo).</span></span><span>&nbsp;</span></p>
        <h4><span>2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span
                    class="underline">Usu&aacute;rios&nbsp;</span></span></h4>
        <p><span>Para os fins destes Termos e Condi&ccedil;&otilde;es,
                &ldquo;<span>Usu&aacute;rio</span>&rdquo; refere-se, em
                geral, a qualquer pessoa que utilize o Aplicativo e inclui os seguintes tipos de Usu&aacute;rios
                espec&iacute;ficos:</span><span>&nbsp;</span></p>
        <p><span>2.1. <span class="underline">&ldquo;Prestador de
                    Assist&ecirc;ncia&rdquo;</span>: S&atilde;o os
                funcion&aacute;rios autorizados, contratados, subcontratados e agentes, representados ou n&atilde;o
                representados, afiliados ao Administrador e que prestam cuidados ou Assist&ecirc;ncia (<em>conforme
                    definido abaixo</em>) ao Paciente.&nbsp;</span></p>
        <p><span>2.2. <span class="underline">&ldquo;Paciente&rdquo;</span>: A pessoa que est&aacute;
                recebendo consultas,
                servi&ccedil;os m&eacute;dicos hospitalares e cl&iacute;nicos ou cuidados intensivos diretamente do
                Administrador.</span></p>
        <p><span>2.3. <span>&ldquo;Assistente&rdquo;</span>: Uma pessoa, que
                n&atilde;o seja o Prestador de
                Assist&ecirc;ncia, que o Paciente designou para prestar-lhe assist&ecirc;ncia e apoio no uso do
                Aplicativo. A menos que o contexto exija de outra forma, quando nestes Termos e Condi&ccedil;&otilde;es
                for feita refer&ecirc;ncia ao Paciente, isso ser&aacute; considerado como incluindo tamb&eacute;m o
                Assistente.&nbsp;</span><span>&nbsp;</span></p>
        <h4><span>3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span class="underline">Descri&ccedil;&atilde;o do
                    aplicativo</span>&nbsp;</span></h4>
        <p><span>O Aplicativo consiste em uma solu&ccedil;&atilde;o de
                software baseada na Web, acess&iacute;vel por meio de um aplicativo m&oacute;vel (app) e/ou portal da
                Web (quando dispon&iacute;vel), que fornece ao Usu&aacute;rio as Fun&ccedil;&otilde;es (conforme
                definido abaixo), a fim de apoiar os Usu&aacute;rios nos aspectos emocionais e f&iacute;sicos do
                c&acirc;ncer.&nbsp;</span><span>&nbsp;</span></p>
        <p><span><span>O
                    Aplicativo n&atilde;o se destina a fornecer servi&ccedil;os que envolvam, direta ou indiretamente,
                    qualquer atividade que implique</span> <span>a
                    presta&ccedil;&atilde;o de servi&ccedil;os de sa&uacute;de ou atividades que sejam restritas ou
                    sujeitas a regulamenta&ccedil;&atilde;o, incluindo, entre outros,</span> <span>diagn&oacute;stico,
                    prescri&ccedil;&atilde;o para uso de
                    medicamentos ou farmacovigil&acirc;ncia, telemedicina ou teleorienta&ccedil;&atilde;o.</span> As
                fun&ccedil;&otilde;es do Aplicativo s&atilde;o principalmente as seguintes
                (&ldquo;<span>Fun&ccedil;&otilde;es</span>&rdquo;):</span><span>&nbsp;</span>
        </p>
        <ol>
            <li><span>Troca de informa&ccedil;&otilde;es para
                    presta&ccedil;&atilde;o de Assist&ecirc;ncia: O Aplicativo permite a intera&ccedil;&atilde;o entre
                    diferentes Usu&aacute;rios para apoiar o Paciente nos aspectos emocionais e f&iacute;sicos do
                    c&acirc;ncer (&ldquo;<span class="underline">Assist&ecirc;ncia</span>&rdquo;), por
                    meio de materiais informativos
                    produzidos e selecionados pelo Administrador ou por terceiros (&ldquo;<span
                        class="underline">Conte&uacute;do</span>&rdquo;), que
                    consistem em sugest&otilde;es de dietas criadas pelo Administrador, conte&uacute;do educacional e
                    mensagens por meio de um sistema de mensagens ou bate-papo, como, por exemplo, o WhatsApp ou outros
                    aplicativos semelhantes. &nbsp;</span></li>
            <li><span>Ferramentas de assist&ecirc;ncia: O Aplicativo fornece
                    (i) alertas e notifica&ccedil;&otilde;es que lembram o Paciente de aspectos de seu tratamento, como
                    o acompanhamento de planos de nutri&ccedil;&atilde;o, cronograma de medica&ccedil;&atilde;o e
                    consultas m&eacute;dicas, e (ii) um bate-papo para intera&ccedil;&atilde;o entre Usu&aacute;rios
                    para monitorar aspectos do tratamento do Paciente e cuidar de seu estado emocional e
                    f&iacute;sico.&nbsp;</span>
                <ol>
                    <li>Carregamento de informa&ccedil;&otilde;es: O aplicativo permite que as informa&ccedil;&otilde;es
                        do Paciente sejam carregadas para melhorar a presta&ccedil;&atilde;o de Assist&ecirc;ncia. Por
                        exemplo, os Pacientes podem relatar seu humor, fazer anota&ccedil;&otilde;es para futuras
                        consultas m&eacute;dicas, compartilhar efeitos colaterais do tratamento, carregar documentos
                        digitalizados para compartilhar com o Prestador de Assist&ecirc;ncia, registrar a
                        ingest&atilde;o de alimentos etc.&nbsp;</li>
                </ol>
            </li>
        </ol>
        <h4><span>4. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span class="underline">Requisitos de
                    idade</span>&nbsp;</span></h4>
        <p><span>4.1. Para usar o Aplicativo, o Usu&aacute;rio deve ter pelo
                menos 18 anos de idade ou a idade necess&aacute;ria para ser considerado maior de idade.&nbsp;</span>
        </p>
        <p><span>4.2. Mediante a aceita&ccedil;&atilde;o destes Termos e
                Condi&ccedil;&otilde;es, o Usu&aacute;rio declara, representa e garante que tem 18 anos de idade e/ou
                &eacute; maior de idade de acordo com as leis de sua jurisdi&ccedil;&atilde;o, e que possui a capacidade
                de se vincular legalmente e celebrar contratos, e que todas as informa&ccedil;&otilde;es enviadas em seu
                registro para o Aplicativo s&atilde;o verdadeiras. O Administrador se reserva o direito de solicitar a
                comprova&ccedil;&atilde;o de idade dos Usu&aacute;rios. O Administrador poder&aacute; suspender, a seu
                crit&eacute;rio e a qualquer momento, as contas at&eacute; que seja fornecida prova suficiente de
                idade.</span></p>
        <p><span>4.3. O Administrador poder&aacute;, a seu exclusivo
                crit&eacute;rio e a qualquer momento, recusar-se a fornecer acesso ao Aplicativo a qualquer pessoa e
                alterar os crit&eacute;rios de admiss&atilde;o ao Aplicativo.&nbsp;</span><span>&nbsp;</span></p>
        <h4><span>5. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span class="underline">Registro</span>&nbsp;</span>
        </h4>
        <p><span>5.1. O Usu&aacute;rio deve se registrar no Aplicativo para
                poder usar as Fun&ccedil;&otilde;es. Ap&oacute;s o registro, o nome de usu&aacute;rio do (i) Prestador
                de Assist&ecirc;ncia ser&aacute; seu ID de e-mail, (ii) Paciente ser&aacute; seu ID de Paciente
                registrado no Sistema EHR e (iii) Assistente ser&aacute; seu n&uacute;mero de celular. O Usu&aacute;rio
                dever&aacute; fornecer as informa&ccedil;&otilde;es exigidas pelo Aplicativo, que ser&atilde;o
                atribu&iacute;das ao perfil do Usu&aacute;rio.</span></p>
        <p><span>5.2. O Usu&aacute;rio deve fornecer
                informa&ccedil;&otilde;es verdadeiras, precisas, completas e atualizadas para o registro. Se n&atilde;o
                o fizer, isso constituir&aacute; uma viola&ccedil;&atilde;o dos Termos e Condi&ccedil;&otilde;es, o que
                poder&aacute; resultar no cancelamento ou na suspens&atilde;o imediata de sua conta.&nbsp;</span></p>
        <p><span>5.3. O Administrador ser&aacute; respons&aacute;vel por
                obter a autoriza&ccedil;&atilde;o pr&eacute;via, expressa e informada do Prestador de Assist&ecirc;ncia
                e do Paciente para o registro, de acordo com as disposi&ccedil;&otilde;es da legisla&ccedil;&atilde;o
                aplic&aacute;vel.&nbsp;</span></p>
        <p><span>5.4. O Operador da Plataforma se reserva o direito, a seu
                crit&eacute;rio absoluto, de rejeitar o registro ou cancelar a conta de um Usu&aacute;rio em caso de
                viola&ccedil;&atilde;o destes Termos e Condi&ccedil;&otilde;es.&nbsp;</span></p>
        <p><span>5.5. O Usu&aacute;rio &eacute; o &uacute;nico
                respons&aacute;vel pela atividade realizada em sua conta e ser&aacute; respons&aacute;vel por manter sua
                senha segura e confidencial. Dessa forma, todas as atividades realizadas pelo Usu&aacute;rio no
                Aplicativo ou por meio dele s&atilde;o de sua inteira responsabilidade e, portanto, o Usu&aacute;rio se
                compromete a manter o Operador da Plataforma livre de danos por quaisquer preju&iacute;zos que resultem
                do uso do Aplicativo.&nbsp;</span></p>
        <p><span>5.6. O Usu&aacute;rio deve notificar imediatamente o
                Operador da Plataforma, por escrito, sobre qualquer uso n&atilde;o autorizado de sua conta ou qualquer
                outra viola&ccedil;&atilde;o de seguran&ccedil;a relacionada &agrave; mesma, para que sejam tomadas as
                medidas necess&aacute;rias.&nbsp;&nbsp;</span><span>&nbsp;</span></p>
        <h4>
            <span>6. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span class="underline">Acordo de
                    uso do aplicativo</span> </span>
        </h4>
        <p><span>6.1. Por meio de seu registro no Aplicativo, o
                Usu&aacute;rio declara, representa e garante que aceita e consente com os presentes Termos e
                Condi&ccedil;&otilde;es, todas as regras operacionais, pol&iacute;ticas e procedimentos que s&atilde;o
                publicados no Aplicativo ou que s&atilde;o publicados ap&oacute;s a aceita&ccedil;&atilde;o dos Termos e
                Condi&ccedil;&otilde;es.&nbsp;</span></p>
        <p><span>6.2. O acesso &agrave;s Fun&ccedil;&otilde;es est&aacute;
                sujeito &agrave; aceita&ccedil;&atilde;o dos Termos e Condi&ccedil;&otilde;es. O Operador da Plataforma
                se reserva o direito de modificar, adicionar ou substituir os presentes Termos e
                Condi&ccedil;&otilde;es, a qualquer momento e sem aviso pr&eacute;vio, por meio da
                publica&ccedil;&atilde;o dos termos e condi&ccedil;&otilde;es que foram modificados, substitu&iacute;dos
                ou adicionados no Aplicativo. Essas modifica&ccedil;&otilde;es tamb&eacute;m dever&atilde;o ser
                informadas por meio de notifica&ccedil;&otilde;es aos usu&aacute;rios.</span></p>
        <p class="align-left"><span>6.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Se o
                Usu&aacute;rio n&atilde;o concordar com estes Termos e Condi&ccedil;&otilde;es, ele n&atilde;o
                dever&aacute; fazer login nem usar o Aplicativo.&nbsp;</span></p>
        <p><span>6.4. O uso das Fun&ccedil;&otilde;es &eacute; limitado
                principalmente ao pa&iacute;s em que a conta foi criada. O Conte&uacute;do dispon&iacute;vel no
                Aplicativo pode ser limitado e variar de acordo com o local.&nbsp;</span></p>
        <p><span>6.5. O Usu&aacute;rio ser&aacute; o &uacute;nico
                respons&aacute;vel pelo acesso &agrave; Internet necess&aacute;rio para o funcionamento adequado do
                Aplicativo. Todos os custos de acesso &agrave; Internet ser&atilde;o &agrave;s custas do
                Usu&aacute;rio.&nbsp;</span><span>&nbsp;</span></p>
        <h4><span>7. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span class="underline">Altera&ccedil;&otilde;es no
                    aplicativo e nos termos e
                    condi&ccedil;&otilde;es</span>&nbsp;</span></h4>
        <p><span>7.1. O uso do Aplicativo ap&oacute;s a
                publica&ccedil;&atilde;o de altera&ccedil;&otilde;es nos Termos e Condi&ccedil;&otilde;es constitui
                aceita&ccedil;&atilde;o dessas altera&ccedil;&otilde;es e de todos os Termos e
                Condi&ccedil;&otilde;es.&nbsp;&nbsp;</span></p>
        <p><span>7.2. O Operador da Plataforma se reserva o direito de
                suspender e restringir o acesso ao Aplicativo. Qualquer responsabilidade que possa ser atribu&iacute;da
                ao Operador da Plataforma como resultado do acima exposto, seja permanente ou tempor&aacute;ria, sofrida
                pelo Aplicativo est&aacute; expressamente exclu&iacute;da.&nbsp;</span></p>
        <p><span>7.3. Os Usu&aacute;rios s&atilde;o respons&aacute;veis por
                consultar periodicamente os Termos e Condi&ccedil;&otilde;es, bem como os demais documentos publicados
                no Aplicativo, a fim de se manterem informados sobre poss&iacute;veis modifica&ccedil;&otilde;es,
                adi&ccedil;&otilde;es ou substitui&ccedil;&otilde;es dos mesmos.</span><span>&nbsp;</span></p>
        <h4><span>8. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span class="underline">Obriga&ccedil;&otilde;es gerais
                    dos
                    usu&aacute;rios</span>&nbsp;</span></h4>
        <p><span>8.1. &nbsp;&nbsp;&nbsp; O Usu&aacute;rio se compromete a
                n&atilde;o usar o Aplicativo para fins que sejam contr&aacute;rios aos Termos e Condi&ccedil;&otilde;es,
                &agrave;s leis da Rep&uacute;blica Federativa do Brasil e/ou &agrave;s leis da jurisdi&ccedil;&atilde;o
                que lhes forem aplic&aacute;veis.&nbsp;</span></p>
        <p><span>8.2. Os usu&aacute;rios que fizerem uso do Aplicativo,
                incluindo todas as caracter&iacute;sticas e Fun&ccedil;&otilde;es a ele relacionadas, concordam em
                n&atilde;o arquivar, fazer download (exceto o cache necess&aacute;rio para uso pessoal), reproduzir,
                distribuir, modificar, comunicar, mostrar, exibir, executar, copiar, duplicar, publicar, conceder
                licen&ccedil;as, criar trabalhos derivados com base no Aplicativo, oferecer para venda ou usar o
                Conte&uacute;do e/ou as informa&ccedil;&otilde;es contidas ou obtidas do Aplicativo.&nbsp;</span></p>
        <p><span>8.3. O Usu&aacute;rio se compromete a n&atilde;o usar
                qualquer <em>spider, scraper </em>ou outro meio automatizado para acessar o Aplicativo e n&atilde;o
                deduzir, fazer engenharia reversa, decodificar, decompilar, desmontar ou tentar derivar o
                c&oacute;digo-fonte ou ideias ou algoritmos subjacentes de qualquer parte do Aplicativo ou de qualquer
                uma de suas Fun&ccedil;&otilde;es.&nbsp;</span></p>
        <p><span>8.4. O Usu&aacute;rio se compromete a n&atilde;o fazer
                upload, publicar, enviar por e-mail ou comunicar de qualquer outra forma qualquer material projetado
                para interromper, destruir ou limitar a funcionalidade de qualquer software, hardware ou dispositivo
                para a opera&ccedil;&atilde;o do Aplicativo.&nbsp;</span></p>
        <p><span>8.5. &Eacute; proibida a modifica&ccedil;&atilde;o,
                reprodu&ccedil;&atilde;o, publica&ccedil;&atilde;o ou transfer&ecirc;ncia de informa&ccedil;&otilde;es a
                terceiros que n&atilde;o estejam expressamente autorizadas nestes Termos e Condi&ccedil;&otilde;es. O
                Operador da Plataforma se reserva o direito, a seu crit&eacute;rio absoluto, de negar ou cancelar o
                acesso ao Aplicativo se forem detectadas condutas ou atividades que, a crit&eacute;rio exclusivo do
                Operador da Plataforma, atuem contra a estes Termos e Condi&ccedil;&otilde;es.</span><span>&nbsp;</span>
        </p>
        <h4><span>9. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span class="underline">Obriga&ccedil;&otilde;es
                    espec&iacute;ficas dos
                    usu&aacute;rios</span></span></h4>
        <p><span>Ao aceitar estes Termos e Condi&ccedil;&otilde;es, o
                Usu&aacute;rio concorda, diretamente ou por meio de um intermedi&aacute;rio, com o seguinte em
                rela&ccedil;&atilde;o ao Aplicativo:</span><span>&nbsp;</span></p>
        <p><span>9.1. N&atilde;o usar o Aplicativo para obter a
                presta&ccedil;&atilde;o de servi&ccedil;os de sa&uacute;de ou atividades que sejam restritas ou sujeitas
                a regulamenta&ccedil;&atilde;o. Isso inclui, mas n&atilde;o se limita a diagn&oacute;stico,
                prescri&ccedil;&atilde;o para uso de medicamentos ou farmacovigil&acirc;ncia, telemedicina e tratamento
                de teleorienta&ccedil;&atilde;o.</span></p>
        <p class="align-left"><span>9.2. &nbsp;&nbsp;&nbsp; N&atilde;o violar os
                direitos de propriedade intelectual.</span></p>
        <p><span>9.3. N&atilde;o se envolver em atividades que constituam
                pr&aacute;ticas anticompetitivas, concorr&ecirc;ncia desleal ou violem os direitos de
                prote&ccedil;&atilde;o ao consumidor.&nbsp;</span></p>
        <p class="align-left"><span>9.4. &nbsp;&nbsp;&nbsp; N&atilde;o promover
                informa&ccedil;&otilde;es imprecisas ou enganosas.&nbsp;</span></p>
        <p><span>9.5. N&atilde;o se envolver em atividades criminosas,
                especialmente aquelas relacionadas ao uso de tecnologia e informa&ccedil;&otilde;es e &agrave;
                substitui&ccedil;&atilde;o de terceiros ou fraude.&nbsp;</span></p>
        <p><span>9.6. N&atilde;o se envolver em atividades que constituam
                a&ccedil;&otilde;es obscenas, ofensivas ou de invas&atilde;o da privacidade de terceiros.&nbsp;</span>
        </p>
        <p><span>9.7. N&atilde;o se envolver em difama&ccedil;&atilde;o,
                ass&eacute;dio, intimida&ccedil;&atilde;o, amea&ccedil;as ou viola&ccedil;&otilde;es de qualquer
                liberdade p&uacute;blica ou direito civil ou humano ou quaisquer outros direitos de propriedade,
                privacidade e/ou publicidade de outros Usu&aacute;rios ou terceiros.&nbsp;</span></p>
        <p class="align-left"><span>9.8. &nbsp;&nbsp;&nbsp; N&atilde;o violar as
                normas de prote&ccedil;&atilde;o de dados pessoais.&nbsp;</span></p>
        <p><span>9.9. N&atilde;o impor &ocirc;nus n&atilde;o
                razo&aacute;veis ou desproporcionais ao Operador da Plataforma e/ou &agrave; infraestrutura do
                Aplicativo. Os crit&eacute;rios para definir o que &eacute; considerado um &ocirc;nus n&atilde;o
                razo&aacute;vel ou desproporcional fica inteiramente a crit&eacute;rio do Operador da Plataforma
                (conforme definido abaixo).&nbsp;</span></p>
        <p><span>9.10. N&atilde;o interferir ou tentar interferir na
                opera&ccedil;&atilde;o correta do Aplicativo.&nbsp;</span></p>
        <p><span>9.11. N&atilde;o duplicar, distribuir, enviar ou
                transferir, de forma alguma, quaisquer das Fun&ccedil;&otilde;es as quais o Usu&aacute;rio possa ter
                acesso.&nbsp;</span></p>
        <p><span>9.12. N&atilde;o usar este Aplicativo como um meio para
                realizar atividades ilegais ou n&atilde;o autorizadas, seja no Brasil ou em qualquer outro
                pa&iacute;s.&nbsp;</span></p>
        <p><span>9.13. Denunciar qualquer viola&ccedil;&atilde;o dos Termos
                e Condi&ccedil;&otilde;es por parte de outros Usu&aacute;rios ou terceiros de que tenha conhecimento.
                Com este prop&oacute;sito, dever&aacute; enviar um e-mail para o endere&ccedil;o eletr&ocirc;nico <span
                    class="underline blue">support@myalivius.com</span>, para que o
                Operador da Plataforma possa tomar as medidas que considerar adequadas.&nbsp;</span></p>
        <p><span>9.14. N&atilde;o fazer upload de nenhum software ou outro
                material protegido por leis de propriedade intelectual para o Aplicativo, a menos que autorizado pelo
                propriet&aacute;rio dos referidos direitos.&nbsp;</span></p>
        <p><span>9.15. N&atilde;o fazer upload de arquivos no Aplicativo que
                contenham v&iacute;rus ou qualquer outro arquivo ou software que possa afetar a
                programa&ccedil;&atilde;o do Aplicativo.&nbsp;</span></p>
        <p><span>9.16. N&atilde;o armazenar qualquer tipo de publicidade no
                Aplicativo, tendo em vista que as Fun&ccedil;&otilde;es n&atilde;o podem ser usadas para fins comerciais
                diferentes dos aqui estabelecidos.</span></p>
        <p><span>9.17. N&atilde;o compartilhar a senha e proteg&ecirc;-la
                para evitar o acesso ao perfil do usu&aacute;rio no Aplicativo por pessoas que n&atilde;o sejam o
                Usu&aacute;rio.</span><span>&nbsp;</span></p>
        <h4><span>10. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span class="underline">Obriga&ccedil;&otilde;es do
                    administrador</span></span></h4>
        <p><span>10.1. Recomendar o Aplicativo conforme julgarem adequado e
                apropriado, de acordo com os termos acordados nestes Termos e Condi&ccedil;&otilde;es.&nbsp;</span></p>
        <p><span>10.2. Caso considerem necess&aacute;rio, fornecer suporte e
                manuten&ccedil;&atilde;o do Aplicativo, o que poder&atilde;o fazer por conta pr&oacute;pria ou por meio
                de terceiros, ap&oacute;s obterem o consentimento pr&eacute;vio do Operador da Plataforma.&nbsp;</span>
        </p>
        <p><span>10.3. Cumprir os regulamentos de prote&ccedil;&atilde;o de
                dados aplic&aacute;veis contidos na Lei Federal n&deg; 13.709/2018 na Pol&iacute;tica de Privacidade do
                Aplicativo, bem como nas normas que a modifiquem, complementem ou revoguem, e no PPI.</span></p>
        <p class="align-left"><span><strong>&nbsp;</strong></span></p>
        <h4><span>11. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span class="underline">Declara&ccedil;&otilde;es e garantias
                    do
                    usu&aacute;rio</span></span></h4>
        <p><span>Ao aceitar estes Termos e Condi&ccedil;&otilde;es, os
                Usu&aacute;rios declaram, representam e garantem que entendem, aceitam e reconhecem
                que:</span><span>&nbsp;</span></p>
        <p><span>11.1. O Aplicativo n&atilde;o se destina a fins de pesquisa
                cient&iacute;fica e n&atilde;o deve ser usado para medir a efic&aacute;cia ou a seguran&ccedil;a de
                qualquer produto farmac&ecirc;utico ou tecnologia de sa&uacute;de.&nbsp;</span></p>
        <p><span>11.2. O Aplicativo pode fornecer opini&otilde;es de
                especialistas consultados em um determinado campo relacionado ao Conte&uacute;do. Tais
                informa&ccedil;&otilde;es representam apenas as opini&otilde;es dos respectivos especialistas, que
                n&atilde;o s&atilde;o necessariamente as do Administrador.&nbsp;</span></p>
        <p><span>11.3. O Operador da Plataforma n&atilde;o garante que o
                Conte&uacute;do e as informa&ccedil;&otilde;es dispon&iacute;veis no Aplicativo estejam livres de erros
                ou imprecis&otilde;es. Al&eacute;m disso, os Usu&aacute;rios declaram que entendem e est&atilde;o
                satisfeitos com o Aplicativo e suas Fun&ccedil;&otilde;es. Portanto, o Usu&aacute;rio n&atilde;o
                poder&aacute; invocar repara&ccedil;&atilde;o de v&iacute;cios ocultos ou institui&ccedil;&otilde;es
                legais similares durante ou ap&oacute;s a execu&ccedil;&atilde;o dos Termos e
                Condi&ccedil;&otilde;es.&nbsp;&nbsp;</span></p>
        <p><span>11.4. O Operador da Plataforma n&atilde;o garante a
                disponibilidade, a infalibilidade e a continuidade da opera&ccedil;&atilde;o do Aplicativo e de suas
                Fun&ccedil;&otilde;es. Se for o caso, o Operador da Plataforma poder&aacute; avisar sobre
                interrup&ccedil;&otilde;es no funcionamento do Aplicativo e/ou das Funcionalidades. No caso de qualquer
                interrup&ccedil;&atilde;o no funcionamento do Aplicativo e/ou das Funcionalidades, o Operador da
                Plataforma n&atilde;o ser&aacute; respons&aacute;vel por quaisquer danos de qualquer
                natureza.&nbsp;</span></p>
        <p><span>11.5. O Operador da Plataforma n&atilde;o garante que o
                Conte&uacute;do e as informa&ccedil;&otilde;es dispon&iacute;veis no Aplicativo sejam suficientes e/ou
                &uacute;teis para o Usu&aacute;rio. Al&eacute;m disso, o Conte&uacute;do e as Fun&ccedil;&otilde;es
                n&atilde;o constituem a presta&ccedil;&atilde;o de servi&ccedil;os m&eacute;dicos, de sa&uacute;de e
                afins, de acordo com os regulamentos aplic&aacute;veis.&nbsp;</span></p>
        <p><span>11.6. O operador da plataforma tem o direito de rejeitar a
                entrada de qualquer pessoa, a qualquer momento e por qualquer motivo.&nbsp;</span></p>
        <p><span>11.7. O Operador da Plataforma (conforme definido abaixo)
                tem o direito de usar os Dados Pessoais (conforme definido abaixo) a seu pr&oacute;prio crit&eacute;rio,
                de acordo com os Termos e Condi&ccedil;&otilde;es e a PPI.&nbsp;</span></p>
        <p><span>11.8. O Operador da Plataforma (conforme definido abaixo)
                n&atilde;o &eacute; respons&aacute;vel pelas Fun&ccedil;&otilde;es e Conte&uacute;do dos
                Aplicativos.</span></p>
        <p><span>11.9. O Operador da Plataforma tem o direito de usar o
                Conte&uacute;do fornecido pelos Usu&aacute;rios de acordo com as disposi&ccedil;&otilde;es dos Termos e
                Condi&ccedil;&otilde;es.&nbsp;</span></p>
        <p><span>11.10. O Operador da Plataforma poder&aacute; ter acesso ou
                permitir que outros terceiros, conforme julgar necess&aacute;rio, forne&ccedil;am suporte e
                manuten&ccedil;&atilde;o ao Aplicativo.&nbsp;</span></p>
        <p><span>11.11. O Operador da Plataforma n&atilde;o garante e,
                portanto, n&atilde;o se responsabiliza pela presen&ccedil;a de v&iacute;rus ou outros elementos nocivos
                no Conte&uacute;do e no Aplicativo ou que possam surgir como resultado do acesso ao Aplicativo, que
                possam causar altera&ccedil;&otilde;es nos sistemas e/ou dispositivos inform&aacute;ticos dos
                Usu&aacute;rios ou de terceiros ou nos arquivos armazenados em tais sistemas.&nbsp;</span></p>
        <p><span>11.12. O Operador da Plataforma poder&aacute;
                disponibilizar as informa&ccedil;&otilde;es inseridas pelos Usu&aacute;rios ap&oacute;s a
                cessa&ccedil;&atilde;o do uso do Aplicativo, de acordo com o disposto nos Termos e
                Condi&ccedil;&otilde;es e na PPI.&nbsp;</span><strong>&nbsp;</strong></p>
        <h4><span>12. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span class="underline">Privacidade e dados
                    pessoais</span>&nbsp;</span></h4>
        <p><span>12.1. Ao usar o Aplicativo, o Usu&aacute;rio concede ao
                Administrador e ao Operador da Plataforma o consentimento expresso e informado para que quaisquer Dados
                Pessoais (conforme definido abaixo) coletados como resultado do uso do Aplicativo sejam usados,
                processados, transferidos, transmitidos e/ou compartilhados nacional e internacionalmente, inclusive
                para terceiros localizados em pa&iacute;ses que n&atilde;o oferecem n&iacute;veis adequados de
                prote&ccedil;&atilde;o (&ldquo;<span class="underline">Processamento</span>&rdquo;).
                Para reduzir os riscos, tomamos medidas como a
                revis&atilde;o dos padr&otilde;es de privacidade e seguran&ccedil;a de terceiros e/ou a
                execu&ccedil;&atilde;o de contratos adequados que incluam regulamentos espec&iacute;ficos sobre o
                processamento de Dados Pessoais.</span></p>
        <p><span>12.2. Os Dados Pessoais podem se referir &agrave;s
                seguintes informa&ccedil;&otilde;es sobre o Usu&aacute;rio, que permitem identific&aacute;-lo e
                reconhec&ecirc;-lo como indiv&iacute;duo: dados de identifica&ccedil;&atilde;o (nome), dados
                demogr&aacute;ficos (idade, sexo), dados de contato (n&uacute;mero de telefone, endere&ccedil;o de
                e-mail), dados m&eacute;dicos, como hist&oacute;rico m&eacute;dico, tratamento m&eacute;dico, etc. (isso
                se aplica apenas ao Paciente), informa&ccedil;&otilde;es relacionadas ao uso do Aplicativo, vinculadas
                aos Usu&aacute;rios, que permitem identific&aacute;-los, bem como detalhes pessoais mencionados em
                conversas de bate-papo (&ldquo;<span class="underline">Dados
                    Pessoais</span>&rdquo;). <strong>&nbsp;</strong></span></p>
        <p><span>12.3. O processamento ocorrer&aacute; para as seguintes
                finalidades: (i) a troca de informa&ccedil;&otilde;es para prestar Assist&ecirc;ncia; (ii) o envio de
                alertas e notifica&ccedil;&otilde;es de dentro do Aplicativo; (iii) o envio de alertas e mensagens por
                meio de aplicativos de mensagens de terceiros, como o WhatsApp ou aplicativos similares, entre outros
                (iv) o envio e a troca de mensagens instant&acirc;neas por meio de chat; (v) a hospedagem do Aplicativo
                em servidores externos; (vi) os Dados Pessoais acessados pelo Administrador e/ou pelo Prestador de
                Assist&ecirc;ncia com a finalidade de operar as Funcionalidades e outros usos do Aplicativo; e (vii) a
                realiza&ccedil;&atilde;o de atividades de suporte e manuten&ccedil;&atilde;o pelo Operador da Plataforma
                (conforme definido abaixo). <strong>&nbsp;</strong></span></p>
        <p><span>12.4. Os Dados Pessoais ser&atilde;o coletados dos
                diferentes Usu&aacute;rios que se registrarem e carregarem informa&ccedil;&otilde;es no Aplicativo.
                <strong>&nbsp;</strong></span></p>
        <p><span>12.5. O Usu&aacute;rio, como propriet&aacute;rio dos dados,
                tem os seguintes direitos: (i) conhecer, atualizar, apagar e retificar os Dados Pessoais; (ii) solicitar
                comprova&ccedil;&atilde;o da autoriza&ccedil;&atilde;o concedida ao Administrador; (iii) mediante
                solicita&ccedil;&atilde;o, ser informado sobre o uso dos Dados Pessoais pelo Administrador e pelo
                Operador da Plataforma; (iv) apresentar reclama&ccedil;&atilde;o por viola&ccedil;&otilde;es do regime
                de prote&ccedil;&atilde;o de dados, perante as autoridades competentes; e (v) retirar a
                autoriza&ccedil;&atilde;o para o processamento e/ou solicitar a exclus&atilde;o dos Dados Pessoais, na
                medida em que n&atilde;o haja obriga&ccedil;&otilde;es legais ou contratuais de perman&ecirc;ncia no
                banco de dados.&nbsp;</span></p>
        <p><span>12.6. Para conhecer seus direitos, como exerc&ecirc;-los e
                as finalidades e usos que ser&atilde;o dados aos Dados Pessoais, os</span></p>
        <p class="align-left"><span>Usu&aacute;rios &nbsp;&nbsp;&nbsp;&nbsp;
                poder&atilde;o &nbsp;&nbsp;&nbsp;&nbsp; consultar &nbsp;&nbsp;&nbsp; o &nbsp;&nbsp;&nbsp;&nbsp; PPI
                &nbsp;&nbsp;&nbsp; do &nbsp;&nbsp;&nbsp;&nbsp; Operador &nbsp;&nbsp;&nbsp; da
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Plataforma,
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; que &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; est&aacute; dispon&iacute;vel
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; em <a
                    href="https://mylilaapp.com.co/politica-de-privacidad/">&nbsp;</a></span></p>
        <p><span><a
                    href="https://mylilaapp.com.co/politica-de-privacidad/">https://www.myalivius.com/PrivacyPolicy.html</a><a
                    href="https://mylilaapp.com.co/politica-de-privacidad/">.</a>Qualquer consulta,
                solicita&ccedil;&atilde;o, demanda, reclama&ccedil;&atilde;o ou peti&ccedil;&atilde;o relacionada aos
                Dados Pessoais poder&aacute; ser enviada para <span
                    class="underline blue">dataprivacy@svaaswellness.com.</span> &nbsp;</span></p>
        <p><span>12.7. O Usu&aacute;rio declara, aceita e reconhece ter sido
                informado de que o consentimento para autorizar o processamento de dados pessoais sens&iacute;veis
                &eacute; totalmente opcional.&nbsp;</span></p>
        <p><span>12.8. O Paciente aceita e d&aacute; seu consentimento
                pr&eacute;vio, expresso e informado para que o Assistente relate as informa&ccedil;&otilde;es e realize
                e execute as funcionalidades em nome do Paciente.&nbsp;</span></p>
        <p><span>12.9. De acordo com as disposi&ccedil;&otilde;es da
                Se&ccedil;&atilde;o 11.8, o Paciente e o Assistente aceitam que o Administrador e o Operador da
                Plataforma podem compartilhar as informa&ccedil;&otilde;es do Paciente e do Assistente com
                terceiros.</span><span>&nbsp;</span></p>
        <h4><span>13. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span class="underline">Operador de
                    plataforma</span>&nbsp;</span></h4>
        <p><span>A Svaas Wellness Limited (&ldquo;Operador da
                Plataforma&rdquo;) &eacute; a propriet&aacute;ria e operadora do Aplicativo e tem como objetivo fornecer
                solu&ccedil;&otilde;es digitais de sa&uacute;de que levem a uma melhor qualidade de vida e melhores
                resultados de sa&uacute;de no interesse de grupos de consumidores variados e para diferentes
                regi&otilde;es geogr&aacute;ficas. Nesse contexto, o Operador da Plataforma requer grandes quantidades
                de dados para criar e fornecer benef&iacute;cios de sa&uacute;de para a mais ampla se&ccedil;&atilde;o
                do p&uacute;blico e otimizar as funcionalidades do Aplicativo.</span><span>&nbsp;</span></p>
        <p><span>O Operador da Plataforma e qualquer outra empresa de
                propriedade ou controlada pelo Operador da Plataforma (&ldquo;Afiliados&rdquo;) s&atilde;o os
                propriet&aacute;rios dos direitos de propriedade intelectual do Aplicativo. Os poderes, direitos e
                obriga&ccedil;&otilde;es contidos nas Se&ccedil;&otilde;es 8, 9, 11, 15, 16, 17 e 18 destes Termos e
                Condi&ccedil;&otilde;es s&atilde;o considerados como investidos no Operador da Plataforma e em seus
                Afiliados, que t&ecirc;m o direito de impor o cumprimento pelos Usu&aacute;rios
                diretamente.&nbsp;</span><span>&nbsp;</span></p>
        <p><span>Os Usu&aacute;rios e o Administrador concordam que o
                Operador da Plataforma e seus Afiliados n&atilde;o ser&atilde;o respons&aacute;veis pelas
                Fun&ccedil;&otilde;es e Conte&uacute;do do Aplicativo.</span><span>&nbsp;</span></p>
        <h4><span>14. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;<span class="underline">Dados processados
                    pelo operador da plataforma</span></span></h4>
        <p><span>14.1. Pelo presente, os usu&aacute;rios d&atilde;o
                consentimento pr&eacute;vio, expresso e informado ao Operador da Plataforma para coletar e processar
                Dados Pessoais com a finalidade de otimizar as Funcionalidades do Aplicativo, oferecendo novas
                solu&ccedil;&otilde;es digitais de sa&uacute;de e para opera&ccedil;&otilde;es de Processamento.
                <strong>&nbsp;</strong></span></p>
        <p><span>14.2. O Processamento pelo Administrador da Plataforma
                ocorrer&aacute; para as seguintes finalidades: (i) cumprimento de suas obriga&ccedil;&otilde;es como
                contratante do Administrador; (ii) hospedagem da Plataforma em servidores externos, inclusive por
                terceiros localizados em pa&iacute;ses que n&atilde;o ofere&ccedil;am n&iacute;veis adequados de
                prote&ccedil;&atilde;o; (iii) presta&ccedil;&atilde;o, direta ou indireta, de suporte operacional,
                corretivo ou preventivo sobre qualquer incidente relatado pelo Usu&aacute;rio ou diagnosticado pelo
                Provedor de Hospedagem Web; (iv) solucionar problemas t&eacute;cnicos, erros e bugs no Aplicativo; (v)
                identificar oportunidades de melhoria de servi&ccedil;o, aprimorar recursos e otimizar a
                experi&ecirc;ncia do usu&aacute;rio; (vi) personalizar recursos, interfaces e funcionalidades; (vii)
                analisar tend&ecirc;ncias de uso para planejar a escalabilidade; e (viii) informar decis&otilde;es
                relacionadas ao desenvolvimento de novos recursos ou aprimoramentos de recursos existentes.
                <strong>&nbsp;</strong></span></p>
        <p><span>14.3. O Usu&aacute;rio, como propriet&aacute;rio dos dados,
                tem os seguintes direitos: (i) conhecer, atualizar, excluir e retificar os Dados Pessoais; (ii)
                solicitar comprova&ccedil;&atilde;o da autoriza&ccedil;&atilde;o concedida ao Administrador, exceto se
                n&atilde;o for exigido por lei; (iii) mediante solicita&ccedil;&atilde;o, ser informado sobre o uso dos
                Dados Pessoais pelo Operador da Plataforma; (iv) apresentar reclama&ccedil;&atilde;o por
                viola&ccedil;&otilde;es do regime de prote&ccedil;&atilde;o de dados, perante as autoridades
                competentes; e (v) revogar a autoriza&ccedil;&atilde;o de processamento e/ou solicitar a exclus&atilde;o
                dos Dados Pessoais, na medida em que n&atilde;o haja obriga&ccedil;&otilde;es legais ou contratuais de
                perman&ecirc;ncia no banco de dados.&nbsp;</span></p>
        <p><span>14.4. O Usu&aacute;rio declara, aceita e reconhece ter sido
                informado de que o consentimento para autorizar o processamento de dados pessoais sens&iacute;veis
                &eacute; totalmente opcional.&nbsp;</span></p>
        <p><span>14.5. Para conhecer os direitos a que t&ecirc;m direito, as
                formas de execu&ccedil;&atilde;o e as finalidades e usos a que os Dados Pessoais ser&atilde;o
                submetidos, os Usu&aacute;rios poder&atilde;o consultar a Pol&iacute;tica de Processamento de
                Informa&ccedil;&otilde;es do Operador da Plataforma, que est&aacute; dispon&iacute;vel em
                https://www.myalivius.com/PrivacyPolicy.html. Qualquer consulta, solicita&ccedil;&atilde;o, demanda,
                reclama&ccedil;&atilde;o ou peti&ccedil;&atilde;o relacionada aos Dados Pessoais processados pelo
                Operador da Plataforma poder&aacute; ser enviada para
                <span class="underline blue">dataprivacy@svaaswellness.com</span>.&nbsp;</span><span>&nbsp;</span></p>
        <h2><span>15. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span class="underline">Limita&ccedil;&atilde;o de
                    responsabilidade, n&atilde;o
                    representa&ccedil;&atilde;o e garantias</span>&nbsp;</span></h2>
        <p><span>15.1. O Operador da Plataforma n&atilde;o &eacute;
                respons&aacute;vel pelo uso do Aplicativo pelo Usu&aacute;rio. O Operador da Plataforma n&atilde;o
                assume nenhuma responsabilidade caso a Assist&ecirc;ncia envolva ou implique, direta ou indiretamente,
                em qualquer atividade que envolva a presta&ccedil;&atilde;o de servi&ccedil;os de sa&uacute;de ou
                atividades que sejam restritas ou sujeitas a regulamenta&ccedil;&otilde;es espec&iacute;ficas. Isso
                inclui, mas n&atilde;o se limita a diagn&oacute;stico, prescri&ccedil;&atilde;o para o uso de
                medicamentos ou farmacovigil&acirc;ncia, tratamento de telemedicina e teleorienta&ccedil;&atilde;o, por
                meio do Aplicativo.&nbsp;</span></p>
        <p><span>15.2. O Conte&uacute;do, o software, as
                Fun&ccedil;&otilde;es e outras informa&ccedil;&otilde;es publicadas no Aplicativo podem conter erros ou
                imprecis&otilde;es. O Operador da Plataforma n&atilde;o garante a precis&atilde;o do Conte&uacute;do,
                software, Fun&ccedil;&otilde;es ou outras informa&ccedil;&otilde;es publicadas no Aplicativo e
                n&atilde;o assume nenhuma responsabilidade por quaisquer imprecis&otilde;es ou erros nesse
                sentido.</span></p>
        <p><span>15.3. Em nenhuma hip&oacute;tese e por nenhuma raz&atilde;o
                o Operador da Plataforma (seus diretores, executivos e subsidi&aacute;rias) ser&aacute;
                respons&aacute;vel por quaisquer danos diretos, indiretos, punitivos, incidentais, especiais,
                consequenciais, compensat&oacute;rios ou qualquer outro tipo de dano derivado ou relacionados ao login
                do Usu&aacute;rio ou ao uso do Aplicativo, independentemente da teoria de responsabilidade que sustente
                &agrave;s alega&ccedil;&otilde;es. <strong>&nbsp;</strong></span></p>
        <p><span>15.4. O Operador da Plataforma n&atilde;o assume qualquer
                responsabilidade ou obriga&ccedil;&atilde;o pela qualidade, validade, efic&aacute;cia e veracidade do
                Conte&uacute;do e das Fun&ccedil;&otilde;es do Aplicativo. <strong>&nbsp;</strong></span></p>
        <p><span>15.5. Os limites e isen&ccedil;&otilde;es de
                responsabilidade aqui contidos nos Termos e Condi&ccedil;&otilde;es continuar&atilde;o e ser&atilde;o
                aplic&aacute;veis caso seja determinado que qualquer disposi&ccedil;&atilde;o em particular n&atilde;o
                cumpra sua finalidade essencial. Os limites e isen&ccedil;&otilde;es de responsabilidade s&atilde;o
                estabelecidos em favor do Operador da Plataforma, n&atilde;o obstante a Se&ccedil;&atilde;o 19 destes
                Termos e Condi&ccedil;&otilde;es.&nbsp;</span><strong>&nbsp;</strong></p>
        <h2><span>16. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span
                    class="underline">Indeniza&ccedil;&atilde;o</span>&nbsp;</span></h2>
        <p><span>16.1. Ao acessar e usar o Aplicativo, o Usu&aacute;rio
                concorda em isentar o Administrador e o Operador da Plataforma de ou em rela&ccedil;&atilde;o a qualquer
                demanda, reclama&ccedil;&atilde;o, investiga&ccedil;&atilde;o administrativa ou judicial,
                a&ccedil;&atilde;o legal ou responsabilidade comprovada baseada ou relacionada &agrave;
                viola&ccedil;&atilde;o destes Termos e Condi&ccedil;&otilde;es e/ou ao uso do Aplicativo. Dessa forma, o
                Usu&aacute;rio n&atilde;o dever&aacute; apresentar qualquer reclama&ccedil;&atilde;o ou
                a&ccedil;&atilde;o de qualquer tipo, ou obter ou tentar obter quaisquer danos de qualquer tipo do
                Administrador ou do Operador da Plataforma como resultado de qualquer decis&atilde;o ou
                a&ccedil;&atilde;o tomada pelo Administrador na administra&ccedil;&atilde;o, gerenciamento,
                opera&ccedil;&atilde;o e execu&ccedil;&atilde;o do Aplicativo.</span></p>
        <p><span>16.2. O Usu&aacute;rio dever&aacute; defender, indenizar e
                isentar de responsabilidade o Administrador, seus Afiliados e cada um de seus funcion&aacute;rios, o
                Operador da Plataforma, seus Afiliados e cada um de seus funcion&aacute;rios, contratados, diretores,
                executivos, fornecedores e representantes de todas as responsabilidades, demandas e despesas, incluindo
                todos os custos relacionados, incluindo honor&aacute;rios advocat&iacute;cios decorrentes ou
                relacionados ao uso ou uso indevido do Aplicativo ou acesso ao Aplicativo, ou qualquer outro uso de
                informa&ccedil;&otilde;es inseridas no Aplicativo pelo Usu&aacute;rio, ou a viola&ccedil;&atilde;o dos
                Termos e Condi&ccedil;&otilde;es ou a viola&ccedil;&atilde;o pelo Usu&aacute;rio ou qualquer terceiro
                usando sua conta, de qualquer propriedade intelectual ou outros direitos de qualquer pessoa ou entidade.
                O Administrador se reserva o direito de assumir a defesa e o controle exclusivos de qualquer assunto
                sujeito a indeniza&ccedil;&atilde;o pelo Usu&aacute;rio. Nesse caso, o Usu&aacute;rio dever&aacute;
                ajudar e cooperar com o Administrador em sua defesa.&nbsp;</span><span>&nbsp;</span>
        </p>
        <h2><span>17. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span class="underline">Rescis&atilde;o</span>&nbsp;</span></h2>
        <p><span>17.1. Estes Termos e Condi&ccedil;&otilde;es, conforme
                alterados, modificados e exclu&iacute;dos, tornam-se vinculativos para o Usu&aacute;rio quando o
                Usu&aacute;rio faz o download ou se registra no Aplicativo e continuar&atilde;o em vigor at&eacute; que
                sejam rescindidos pelo Usu&aacute;rio ou pelo Administrador ou pelo Operador da Plataforma, de acordo
                com os termos aqui contidos. N&atilde;o obstante o acima exposto, as restri&ccedil;&otilde;es,
                obriga&ccedil;&otilde;es e riscos assumidos pelo Usu&aacute;rio ao aceitar os Termos e
                Condi&ccedil;&otilde;es, bem como as limita&ccedil;&otilde;es de responsabilidade e
                indeniza&ccedil;&otilde;es estabelecidas pelo Operador da Plataforma nestes Termos e
                Condi&ccedil;&otilde;es, sobreviver&atilde;o &agrave; rescis&atilde;o dos Termos e
                Condi&ccedil;&otilde;es.&nbsp;</span></p>
        <p><span>17.2. O Administrador ou Operador da Plataforma reserva-se
                o direito de remover automaticamente o Aplicativo e/ou rescindir automaticamente os Termos e
                Condi&ccedil;&otilde;es, no todo ou em parte, a seu crit&eacute;rio exclusivo. A rescis&atilde;o
                poder&aacute; resultar na destrui&ccedil;&atilde;o total de todas as informa&ccedil;&otilde;es
                relacionadas &agrave; conta do Usu&aacute;rio.&nbsp;</span></p>
        <p><span>17.3. Fica expressamente exclu&iacute;da qualquer
                responsabilidade que os Usu&aacute;rios possam atribuir ao Operador da Plataforma em decorr&ecirc;ncia
                da rescis&atilde;o destes Termos e Condi&ccedil;&otilde;es e da exclus&atilde;o do
                Aplicativo.&nbsp;</span><strong>&nbsp;</strong></p>
        <h2><span>18. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="underline"> Direitos de
                    propriedade e direitos de propriedade intelectual
                    do administrador e de terceiros</span>&nbsp;</span></h2>
        <p><span>18.1. Todos os elementos e informa&ccedil;&otilde;es deste
                Aplicativo e de seus Fun&ccedil;&otilde;es, incluindo textos, processos, m&eacute;todos, segredos
                comerciais, know-how, t&eacute;cnicas, formas, imagens, m&uacute;sicas, marcas registradas, logotipos,
                emblemas, nomes comerciais, sons, gr&aacute;ficos, v&iacute;deos, anima&ccedil;&otilde;es e outros
                materiais e ferramentas, sujeitos &agrave; prote&ccedil;&atilde;o de propriedade intelectual, s&atilde;o
                e permanecer&atilde;o de propriedade de seus respectivos propriet&aacute;rios, seja o Administrador, o
                Operador da Plataforma, licenciados, benefici&aacute;rios e, em geral, qualquer terceiro.&nbsp;</span>
        </p>
        <p><span>18.2. Qualquer uso n&atilde;o autorizado dos elementos e
                informa&ccedil;&otilde;es deste Aplicativo que infrinja os direitos de propriedade e propriedade
                intelectual do Administrador, do Operador da Plataforma, de seus contratados, licenciados,
                benefici&aacute;rios e, em geral, de qualquer terceiro, poder&aacute; levar ao in&iacute;cio das
                a&ccedil;&otilde;es legais correspondentes por parte dos titulares de tais direitos.&nbsp;</span></p>
        <p><span>18.3. O acesso e o uso do Aplicativo n&atilde;o
                implicar&atilde;o, de forma alguma, a transfer&ecirc;ncia ou a nega&ccedil;&atilde;o de qualquer
                licen&ccedil;a, concess&atilde;o ou direito de uso de qualquer texto, processo, m&eacute;todo, segredo
                comercial, know-how, t&eacute;cnicas, formas, imagens, m&uacute;sicas, marcas registradas, logotipos,
                emblemas, nomes comerciais, sons, gr&aacute;ficos, v&iacute;deos, anima&ccedil;&otilde;es e outros
                materiais e ferramentas sujeitos &agrave; prote&ccedil;&atilde;o por direitos de propriedade
                intelectual, exceto conforme previsto neste documento.&nbsp;</span></p>
        <p><span>18.4. &Eacute; proibida a cria&ccedil;&atilde;o de
                p&aacute;ginas da Web, gateways de Internet, aplicativos, neg&oacute;cios, documentos eletr&ocirc;nicos
                ou software ou aplicativos de computador de qualquer tipo que contenham hiperlinks ou marcas que
                redirecionem o navegador para qualquer parte do Aplicativo.&nbsp;</span></p>
        <p><span>18.5. Fica expressamente exclu&iacute;do qualquer tipo de
                responsabilidade que o Usu&aacute;rio possa atribuir ao Administrador como consequ&ecirc;ncia da
                viola&ccedil;&atilde;o dos direitos de outros usu&aacute;rios ou terceiros sobre seus bens e direitos de
                propriedade intelectual e industrial.&nbsp;</span></p>
        <p><span>18.6. O Administrador e o Operador da Plataforma n&atilde;o
                assumem qualquer responsabilidade pela propriedade intelectual decorrente do conte&uacute;do dos
                Usu&aacute;rios ou de terceiros e das informa&ccedil;&otilde;es fornecidas pelos Usu&aacute;rios ou
                terceiros em seus produtos ou servi&ccedil;os.&nbsp;</span><strong>&nbsp;</strong></p>
        <h2><span>19. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span class="underline">Viola&ccedil;&atilde;o dos termos e
                    condi&ccedil;&otilde;es</span>&nbsp;</span></h2>
        <p><span>19.1. Qualquer viola&ccedil;&atilde;o dos Termos e
                Condi&ccedil;&otilde;es ou qualquer reclama&ccedil;&atilde;o ou informa&ccedil;&atilde;o recebida pelo
                Operador da Plataforma sobre viola&ccedil;&atilde;o, abuso ou uso indevido destes Termos e
                Condi&ccedil;&otilde;es, do Aplicativo e/ou de seus Fun&ccedil;&otilde;es poder&aacute; ser investigada
                pelo Operador da Plataforma ou por terceiros, e ser&atilde;o tomadas medidas para iniciar todas as
                a&ccedil;&otilde;es legais e extrajudiciais contra os Usu&aacute;rios. Isso ocorre, entre outras
                raz&otilde;es, para p&ocirc;r fim &agrave; conduta e obter quaisquer rem&eacute;dios e
                compensa&ccedil;&otilde;es devidos de acordo com as leis aplic&aacute;veis. A viola&ccedil;&atilde;o
                destes Termos e Condi&ccedil;&otilde;es pode resultar na suspens&atilde;o ou no encerramento de sua
                conta e em responsabilidade civil ou criminal.&nbsp;</span></p>
        <p><span>19.2. Se o Usu&aacute;rio questionar a legalidade de suas
                a&ccedil;&otilde;es durante suas atividades no Aplicativo e/ou o uso de seus Fun&ccedil;&otilde;es,
                n&atilde;o hesite em entrar em contato com o Operador da Plataforma com anteced&ecirc;ncia.&nbsp;</span>
        </p>
        <p><span>19.3. Se o Usu&aacute;rio acreditar que seus direitos foram
                violados por terceiros ou pelo Administrador ou que sua conta foi suspensa ou cancelada por engano ou
                porque sua identidade foi confundida, n&atilde;o hesite em entrar em contato com o Operador da
                Plataforma.</span><span>&nbsp;</span></p>
        <h2><span>20. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span class="underline">Sites e/ou
                    aplicativos de terceiros</span>&nbsp;</span></h2>
        <p><span>20.1. Os sites vinculados ao Aplicativo n&atilde;o
                s&atilde;o de propriedade, operados, controlados ou administrados pelo Operador da Plataforma e,
                portanto, o Operador da Plataforma n&atilde;o &eacute; respons&aacute;vel pelo conte&uacute;do,
                pr&aacute;ticas, pol&iacute;ticas, seguran&ccedil;a, servi&ccedil;os, disponibilidade, conformidade com
                os termos e condi&ccedil;&otilde;es de uso ou pol&iacute;ticas de privacidade.&nbsp;</span></p>
        <p><span>20.2. Qualquer link feito no Aplicativo para sites de
                terceiros n&atilde;o constitui patroc&iacute;nio, prote&ccedil;&atilde;o, endosso, defesa, garantia ou
                suporte de qualquer tipo por parte do Operador da Plataforma em rela&ccedil;&atilde;o ao seu
                conte&uacute;do, pr&aacute;ticas, pol&iacute;ticas, seguran&ccedil;a, servi&ccedil;os, disponibilidade,
                conformidade com os termos e condi&ccedil;&otilde;es de uso ou pol&iacute;ticas de
                privacidade.&nbsp;</span></p>
        <p><span>20.3. O Usu&aacute;rio &eacute; respons&aacute;vel pelo
                acesso e uso de sites de terceiros que estejam vinculados ao Aplicativo e/ou &agrave;s
                Fun&ccedil;&otilde;es.&nbsp;</span></p>
        <p><span>20.4. Fica expressamente exclu&iacute;da qualquer
                responsabilidade que os Usu&aacute;rios possam atribuir ao Operador da Plataforma em decorr&ecirc;ncia
                de danos relacionados ao uso de gateways, servi&ccedil;os, produtos ou conte&uacute;dos que estejam
                dispon&iacute;veis por meio de sites de terceiros vinculados ao
                Aplicativo.&nbsp;</span><span>&nbsp;</span></p>
        <h2><span>21. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span class="underline">Relacionamento
                    entre o operador da plataforma e o
                    usu&aacute;rio</span>&nbsp;</span></h2>
        <p><span>21.1. O Operador da Plataforma n&atilde;o tem nenhuma
                rela&ccedil;&atilde;o jur&iacute;dica com o Usu&aacute;rio al&eacute;m daquela que surge ao longo destes
                Termos e Condi&ccedil;&otilde;es. Portanto, o Operador da Plataforma n&atilde;o &eacute; obrigado a
                tomar medidas com rela&ccedil;&atilde;o ao seguinte:</span></p>
        <ul>
            <li><span>Usu&aacute;rios que podem acessar o Aplicativo e o uso
                    que fazem de seus Fun&ccedil;&otilde;es. <strong>&nbsp;</strong></span></li>
            <li><span>A qualidade, validade, efic&aacute;cia e veracidade do
                    Conte&uacute;do oferecido no Aplicativo.&nbsp;</span></li>
            <li><span>Os efeitos que determinados tipos de Conte&uacute;do
                    podem ter sobre os Usu&aacute;rios.&nbsp;</span></li>
            <li><span>Interpreta&ccedil;&atilde;o dos Usu&aacute;rios do
                    Conte&uacute;do e/ou Fun&ccedil;&otilde;es.&nbsp;</span></li>
        </ul>
        <p><span>21.2. O Operador da Plataforma n&atilde;o garante a
                autenticidade ou veracidade das informa&ccedil;&otilde;es fornecidas no Aplicativo ou por outros
                Usu&aacute;rios sobre o Aplicativo ou sobre a qualidade, validade, efic&aacute;cia e veracidade do
                Conte&uacute;do oferecido.&nbsp;</span><span>&nbsp;</span>
        </p>
        <p class="align-left"><span><strong>22.</strong><strong>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span class="underline">Lei
                        aplic&aacute;vel</span></strong> &nbsp;&nbsp; <strong>&nbsp;</strong></span></p>
        <p><span>Estes Termos e Condi&ccedil;&otilde;es ser&atilde;o regidos
                e interpretados de acordo com as leis da Rep&uacute;blica Federativa do
                Brasil.&nbsp;</span><span>&nbsp;</span></p>
        <h2><span>23. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span class="underline">Divisibilidade</span></span></h2>
        <p><span>Se qualquer termo, disposi&ccedil;&atilde;o ou
                condi&ccedil;&atilde;o destes Termos e Condi&ccedil;&otilde;es ou a aplica&ccedil;&atilde;o dos mesmos a
                qualquer circunst&acirc;ncia for considerada inv&aacute;lida ou inexequ&iacute;vel, a validade ou
                exequibilidade do restante destes Termos e Condi&ccedil;&otilde;es n&atilde;o ser&aacute; afetada por
                isso, e cada um dos outros termos, disposi&ccedil;&otilde;es e condi&ccedil;&otilde;es destes Termos e
                Condi&ccedil;&otilde;es ser&aacute; v&aacute;lido e exequ&iacute;vel na medida m&aacute;xima permitida
                por lei.&nbsp;</span><span>&nbsp;</span></p>
        <h2><span>24. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span class="underline">Contato</span></span></h2>
        <p><span>Se os Usu&aacute;rios tiverem alguma d&uacute;vida ou
                preocupa&ccedil;&atilde;o sobre os Termos e Condi&ccedil;&otilde;es, poder&atilde;o entrar em contato
                com o Operador da Plataforma pelo e-mail
                <span class="underline blue">support@myalivius.com.</span>&nbsp;</span><span>&nbsp;</span>
        </p>
        <p class="align-left"><span>&nbsp;</span></p>
        <p><span>&Uacute;ltima atualiza&ccedil;&atilde;o: agosto 2024</span>
        </p>
        <p class="center"><span>---------------------------x---------------------------</span></p>
        <p class="align-left"><span>&nbsp;</span></p>
        <p class="center"><span>&nbsp;</span></p>
    </div>
    )
}

export default TermsAndConditions;