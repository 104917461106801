import React, { useEffect } from 'react';
const TermsAndConditionsAliviusGo = () => {
    useEffect(() => {
                    window.scrollTo(0, 0); // Scroll to the top of the page
                  }, []);
    return (
        <div class="pp-container">
        <p style={{ textAlign: 'center', fontWeight: 'bold' }}>TERMOS E CONDI&Ccedil;&Otilde;ES DE USO DO APLICATIVO
            &ldquo;ALIVIUS GO&ldquo;</p>

        <p>Leia atentamente os termos e condi&ccedil;&otilde;es de uso (&ldquo;Termos e Condi&ccedil;&otilde;es&rdquo;)
            antes de usar o aplicativo ALIVIUS GO (&ldquo;Aplicativo&rdquo;). Estes Termos e Condi&ccedil;&otilde;es
            estabelecem as condi&ccedil;&otilde;es legais e vinculantes que voc&ecirc;, na qualidade de Usu&aacute;rio
            (conforme definido abaixo) do Aplicativo, compromete-se a cumprir de forma livre e volunt&aacute;ria.</p>

        <p>A Pol&iacute;tica de Processamento de Informa&ccedil;&otilde;es (&ldquo;PPI&rdquo;) do Operador da Plataforma
            (conforme definido abaixo) &eacute; parte integrante destes Termos e Condi&ccedil;&otilde;es. Portanto,
            sempre
            que for feita refer&ecirc;ncia neste texto aos Termos e Condi&ccedil;&otilde;es, tamb&eacute;m ser&aacute;
            feita
            refer&ecirc;ncia &agrave; PPI, que pode ser acessada neste link:
            <a
                href="https://www.myalivius.com/privacy-policy-alivius-go">https://www.myalivius.com/privacy-policy-alivius-go</a>.
        </p>

        <p>Os termos em mai&uacute;sculas usados neste documento e n&atilde;o definidos de outra forma ter&atilde;o o
            significado estabelecido entre aspas, tanto no plural como no singular.</p>

        <p><b>1.&emsp;<span class="bold-underline">Administra&ccedil;&atilde;o do Aplicativo</span></b><br />
            1.1. O acesso ao Aplicativo &eacute; oferecido ao Usu&aacute;rio, conforme solicita&ccedil;&atilde;o da
            Administradora, para facilitar o relacionamento entre o Operador da Plataforma e a
            Administradora(&ldquo;Partes&rdquo;).<br />
            1.2. Este Aplicativo &eacute; administrado pelo hospital registrado de acordo com as leis do Brasil, ,
            com
            experi&ecirc;ncia e autoriza&ccedil;&atilde;o para a presta&ccedil;&atilde;o de servi&ccedil;os
            m&eacute;dicos,
            de sa&uacute;de e relacionados, que incluem, sem limita&ccedil;&atilde;o, atendimento hospitalar e
            ambulatorial,
            diagn&oacute;stico, servi&ccedil;os terap&ecirc;uticos, servi&ccedil;os laboratoriais, medicamentos,
            cuidados de
            enfermagem oncol&oacute;gica, entre outros (&ldquo;Administradora&rdquo;).</p>

        <p>A Administradora assumir&aacute; toda a responsabilidade por todos os conte&uacute;dos e as
            informa&ccedil;&otilde;es transportadas e incorporadas nas e atrav&eacute;s das Fun&ccedil;&otilde;es
            (conforme
            definido abaixo).</p>

        <p><b>2.&emsp;<span class="bold-underline">Usu&aacute;rios</span></b> <br />
            2.1. Para os fins destes Termos e Condi&ccedil;&otilde;es, &ldquo;Usu&aacute;rio&rdquo; refere-se, em geral,
            a
            qualquer pessoa que use o Aplicativo, e cobre especialmente, mas n&atilde;o se limitando, os
            &ldquo;Prestadores
            de Cuidados&rdquo;, assim entendidos como os trabalhadores autorizados, contratados, subcontratados e
            agentes
            com ou sem representa&ccedil;&atilde;o, afiliados &agrave; Administradora, e que prestam cuidados ou
            Assist&ecirc;ncia (conforme definido abaixo) ao &ldquo;Paciente&rdquo;, assim entendido como a pessoa que
            atualmente recebe consulta, servi&ccedil;os m&eacute;dicos hospitalares e cl&iacute;nicos ou terapia
            intensiva,
            diretamente da Administradora.</p>

        <p><b>3.&emsp;<span class="bold-underline">Descri&ccedil;&atilde;o do Aplicativo</span></b><br />
            3.1. O Aplicativo consiste em uma solu&ccedil;&atilde;o de software com base na Internet que pode ser
            acessada
            por meio de um aplicativo (app) para dispositivos m&oacute;veis , que fornece ao Usu&aacute;rio as
            Fun&ccedil;&otilde;es (conforme definido abaixo), a fim de apoiar os Pacientes com os aspectos emocionais e
            f&iacute;sicos do c&acirc;ncer.<br />
            3.2. O Aplicativo n&atilde;o procura prestar servi&ccedil;os que envolvam direta ou indiretamente qualquer
            atividade que implique a presta&ccedil;&atilde;o de servi&ccedil;os de sa&uacute;de ou atividades que sejam
            restritas ou sujeitas a regulamentos, incluindo, sem limita&ccedil;&atilde;o, diagn&oacute;stico,
            prescri&ccedil;&atilde;o para o uso de medicamentos ou farmacovigil&acirc;ncia, telemedicina ou
            teleorienta&ccedil;&atilde;o. No entanto, nada neste documento deve ser entendido como um impedimento para
            que a
            Administradora e seus Prestadores de Cuidados autorizados cumpram suas obriga&ccedil;&otilde;es legais de
            informar &agrave;s autoridades competentes sobre eventos adversos eventualmente comunicados pelos Pacientes
            por
            meio da Plataforma, especialmente, sem limita&ccedil;&atilde;o, &agrave;queles decorrentes da Lei Federal
            13.021/2014, da Resolu&ccedil;&atilde;o RDC n&ordm; 406/2020 e da RDC n&ordm; 36/2013.<br />
            3.3. As fun&ccedil;&otilde;es do Aplicativo s&atilde;o principalmente as seguintes
            (&ldquo;Fun&ccedil;&otilde;es&rdquo;):<br />
            a. Troca de informa&ccedil;&otilde;es para prestar Assist&ecirc;ncia: O Aplicativo permite a
            intera&ccedil;&atilde;o entre diferentes Usu&aacute;rios e suas equipes para apoiar o Paciente com os
            aspectos
            emocionais e f&iacute;sicos do c&acirc;ncer (&ldquo;Assist&ecirc;ncia&rdquo;). <br />
            b. Ferramentas de assist&ecirc;ncia: O Aplicativo fornece (i) alertas e notifica&ccedil;&otilde;es &agrave;
            equipe de Prestadores de Cuidados acerca de a&ccedil;&otilde;es e eventos importantes relativos ao Paciente,
            e
            (ii) uma ferramenta de bate-papo que permite a intera&ccedil;&atilde;o entre a equipe de Prestadores de
            Cuidado,
            bem como entre estes e o Paciente, especialmente para monitorar os aspectos do tratamento do Paciente e
            cuidar
            de seu estado emocional e f&iacute;sico.<br />
            c. Carregamento de informa&ccedil;&otilde;es: O Aplicativo permite que as informa&ccedil;&otilde;es do
            Paciente
            sejam carregadas para melhorar a presta&ccedil;&atilde;o de Assist&ecirc;ncia. Por meio do Aplicativo, os
            Usu&aacute;rios poder&atilde;o, por exemplo, acessar os reportes dos Pacientes relativos ao seu humor,
            dieta,
            registros de peso, efeitos colaterais do tratamento e outros. Al&eacute;m disso, o Aplicativo permite que os
            Usu&aacute;rios da equipe de Prestadores de Cuidados consultem informa&ccedil;&otilde;es relativas ao
            Paciente e
            seu tratamento, incluindo, mas n&atilde;o se limitando, hist&oacute;rico m&eacute;dico, lista de
            comorbidades,
            prescri&ccedil;&otilde;es de medicamentos e outros.<br />
            d. Acesso a informa&ccedil;&otilde;es: O Aplicativo permite que os Usu&aacute;rios acessem as
            informa&ccedil;&otilde;es fornecidas pelos Pacientes por meio de outro aplicativo, denominado 'Alivius', de
            propriedade e controlado pelo Operador da Plataforma e oferecido aos Pacientes do Administrador.</p>

        <p><b>4.&emsp;<span class="bold-underline">Requisitos</span></b><br />
            4.1. Para usar o Aplicativo, o Usu&aacute;rio deve ser profissional da &aacute;rea de sa&uacute;de
            integrante da
            equipe de Prestadores de Cuidados autorizados da Administradora, devendo estar devidamente habilitado de
            acordo
            com as normas aplic&aacute;veis no &acirc;mbito da sua jurisdi&ccedil;&atilde;o competente ao
            exerc&iacute;cio
            de suas atividades. <br />
            4.2. Ap&oacute;s a aceita&ccedil;&atilde;o destes Termos e Condi&ccedil;&otilde;es, o Usu&aacute;rio afirma,
            declara e garante que tem 18 anos de idade e/ou &eacute; maior de idade de acordo com as leis de sua
            jurisdi&ccedil;&atilde;o, e possui a capacidade de assumir e celebrar contratos legalmente, e que todas as
            informa&ccedil;&otilde;es enviadas em seu registro para o Aplicativo s&atilde;o verdadeiras. A
            Administradora
            reserva-se o direito de solicitar provas para comprovar o cumprimento dos requisitos descritos acima pelos
            Usu&aacute;rios. A Administradora pode suspender, a seu crit&eacute;rio e a qualquer momento, qualquer
            registro
            de um Usu&aacute;rio no Aplicativo at&eacute; que seja fornecida prova suficiente dos requisitos previstos
            nesta
            se&ccedil;&atilde;o.<br />
            4.3. A Administradora pode, a seu crit&eacute;rio e a qualquer momento, recusar-se a oferecer acesso ao
            Aplicativo a qualquer pessoa e alterar os crit&eacute;rios de admiss&atilde;o a ele.</p>

        <p><b>5.&emsp;<span class="bold-underline">Registro</span></b><br />
            5.1. O Usu&aacute;rio deve se registrar no Aplicativo para poder usar suas Funcionalidades. Ap&oacute;s o
            registro, o nome de usu&aacute;rio do Prestador de Cuidados, ser&aacute; seu ID de e-mail. O Usu&aacute;rio
            deve
            fornecer as informa&ccedil;&otilde;es exigidas pelo Aplicativo, que ser&atilde;o atribu&iacute;das ao perfil
            do
            Usu&aacute;rio.</p>

        <p>5.2. O Usu&aacute;rio deve fornecer informa&ccedil;&otilde;es verdadeiras, exatas, completas e atualizadas
            para o
            cadastro. N&atilde;o faz&ecirc;-lo constituir&aacute; uma viola&ccedil;&atilde;o dos Termos e
            Condi&ccedil;&otilde;es, o que pode resultar no cancelamento ou suspens&atilde;o imediata da sua conta.
            <br />
            5.3. O Administrador ser&aacute; respons&aacute;vel por obter a autoriza&ccedil;&atilde;o pr&eacute;via,
            expressa e informada do Usu&aacute;rio para o registro, de acordo com as disposi&ccedil;&otilde;es das leis
            aplic&aacute;veis.<br />
            5.4. A Administradora reserva-se o direito, a seu exclusivo crit&eacute;rio, de recusar o registro ou
            cancelar a
            conta de um Usu&aacute;rio em caso de viola&ccedil;&atilde;o destes Termos e Condi&ccedil;&otilde;es. <br />
            5.5. O Usu&aacute;rio &eacute; o &uacute;nico respons&aacute;vel pela atividade realizada em sua conta e
            ser&aacute; respons&aacute;vel por manter sua senha segura e confidencial. Como tal, todas as atividades que
            o
            Usu&aacute;rio realiza no ou atrav&eacute;s do Aplicativo s&atilde;o de inteira responsabilidade do
            Usu&aacute;rio e, como resultado, o Usu&aacute;rio se compromete a manter o Operador da Plataforma livre de
            danos por quaisquer preju&iacute;zos que resultem do uso do Aplicativo. <br />
            5.6. O Usu&aacute;rio notificar&aacute; imediatamente o Operador da Plataforma por escrito sobre qualquer
            uso
            n&atilde;o autorizado da conta do Usu&aacute;rio ou qualquer outra viola&ccedil;&atilde;o de
            seguran&ccedil;a
            relacionada a ela, para a ado&ccedil;&atilde;o das medidas necess&aacute;rias.</p>

        <p><b>6.&emsp;<span class="bold-underline">Contrato de Uso do Aplicativo.</span></b><br />
            6.1. Atrav&eacute;s do seu registo no Aplicativo, o Usu&aacute;rio afirma, declara e garante que aceita e
            concorda com os presentes Termos e Condi&ccedil;&otilde;es, com todas as regras, pol&iacute;ticas e
            procedimentos operacionais que s&atilde;o publicados no Aplicativo ou que s&atilde;o publicados ap&oacute;s
            a
            aceita&ccedil;&atilde;o destes Termos e Condi&ccedil;&otilde;es. <br />
            6.2. O acesso &agrave;s Fun&ccedil;&otilde;es est&aacute; sujeito &agrave; aceita&ccedil;&atilde;o destes
            Termos
            e Condi&ccedil;&otilde;es. O Operador da Plataforma reserva-se o direito de modificar, adicionar ou
            substituir
            os presentes Termos e Condi&ccedil;&otilde;es, a qualquer momento, e sem aviso pr&eacute;vio, atrav&eacute;s
            da
            publica&ccedil;&atilde;o dos termos e condi&ccedil;&otilde;es que foram modificados, substitu&iacute;dos ou
            adicionados, dentro do Aplicativo. Essas modifica&ccedil;&otilde;es tamb&eacute;m ser&atilde;o relatadas por
            meio de notifica&ccedil;&otilde;es do Usu&aacute;rio.<br />
            6.3. Se o Usu&aacute;rio n&atilde;o concordar com estes Termos e Condi&ccedil;&otilde;es, ele n&atilde;o
            deve
            fazer login ou usar o Aplicativo. <br />
            6.4. O uso das Fun&ccedil;&otilde;es &eacute; limitado principalmente ao pa&iacute;s em que a conta foi
            criada.
            As Fun&ccedil;&otilde;es dispon&iacute;veis no Aplicativo podem ser limitadas e variar dependendo da
            localiza&ccedil;&atilde;o em que o Aplicativo &eacute; acessado. <br />
            6.5. O Usu&aacute;rio ser&aacute; o &uacute;nico respons&aacute;vel pelo acesso &agrave; Internet
            necess&aacute;rio para o correto funcionamento do Aplicativo. Todas as cobran&ccedil;as de acesso &agrave;
            Internet ser&atilde;o por conta do Usu&aacute;rio.</p>

        <p><b>7.&emsp;<span class="bold-underline">Altera&ccedil;&otilde;es ao Aplicativo e aos Termos e
                    Condi&ccedil;&otilde;es </span></b><br />
            7.1. O uso do Aplicativo ap&oacute;s a publica&ccedil;&atilde;o das altera&ccedil;&otilde;es feitas nos
            Termos e
            Condi&ccedil;&otilde;es constitui aceita&ccedil;&atilde;o das referidas altera&ccedil;&otilde;es e da
            totalidade
            dos Termos e Condi&ccedil;&otilde;es. <br />
            7.2. O Operador da Plataforma reserva-se o direito de suspender e restringir o acesso de qualquer
            Usu&aacute;rio
            ao Aplicativo, ficando expressamente exclu&iacute;do qualquer tipo de responsabilidade que possa ser
            atribu&iacute;do ao Operador da Plataforma em decorr&ecirc;ncia da referida restri&ccedil;&atilde;o, seja
            ele
            permanente ou tempor&aacute;rio. <br />
            7.3. Os Usu&aacute;rios s&atilde;o respons&aacute;veis por consultar periodicamente os Termos e
            Condi&ccedil;&otilde;es, bem como os demais documentos publicados no Aplicativo, a fim de se manterem
            informados
            sobre poss&iacute;veis modifica&ccedil;&otilde;es, acr&eacute;scimos ou substitui&ccedil;&otilde;es a ele.
        </p>

        <p>
            <b>8.&emsp;<span class="bold-underline">Obriga&ccedil;&otilde;es Gerais do Usu&aacute;rio </span></b><br />
            8.1. O Usu&aacute;rio compromete-se a n&atilde;o usar o Aplicativo para fins vedados nestes Termos e
            Condi&ccedil;&otilde;es, nas leis da Rep&uacute;blica Federativa do Brasil e/ou nas leis de qualquer
            jurisdi&ccedil;&atilde;o aplic&aacute;veis. <br />
            8.2. Os Usu&aacute;rios que fazem uso do Aplicativo, incluindo todas as caracter&iacute;sticas e
            Fun&ccedil;&otilde;es relacionadas a ele, concordam em n&atilde;o arquivar, baixar (exceto o cache
            necess&aacute;rio para uso pessoal), reproduzir, distribuir, modificar, comunicar, mostrar, exibir,
            executar,
            copiar, duplicar, publicar, conceder licen&ccedil;as, criar trabalhos derivados com base no Aplicativo,
            oferecer
            em venda ou usar o conte&uacute;do e/ou informa&ccedil;&otilde;es contidas ou obtidas do Aplicativo. <br />
            8.3. O Usu&aacute;rio compromete-se a n&atilde;o usar qualquer spider, scraper ou outra forma automatizada
            para
            acessar o Aplicativo e a n&atilde;o decompilar, realizar engenharia inversa, decodificar, decompilar,
            desmontar
            ou tentar derivar o c&oacute;digo-fonte ou ideias ou algoritmos subjacentes de qualquer parte do Aplicativo
            ou
            de qualquer uma de suas Fun&ccedil;&otilde;es. <br />
            8.4. O Usu&aacute;rio compromete-se a n&atilde;o carregar, publicar, enviar por e-mail ou comunicar de
            qualquer
            outra forma qualquer material concebido, interromper, destruir ou limitar a funcionalidade de qualquer
            software,
            hardware ou dispositivo para o funcionamento do Aplicativo. <br />
            8.5. &Eacute; proibida a modifica&ccedil;&atilde;o, reprodu&ccedil;&atilde;o, publica&ccedil;&atilde;o ou
            transfer&ecirc;ncia de informa&ccedil;&otilde;es a terceiros que n&atilde;o estejam expressamente
            autorizadas
            nestes Termos e Condi&ccedil;&otilde;es. O Operador da Plataforma reserva-se o direito, a seu exclusivo
            crit&eacute;rio, de recusar ou cancelar o acesso ao Aplicativo se forem detectadas condutas ou atividades
            que, a
            crit&eacute;rio exclusivo da Administradora, atuem contra estes Termos e Condi&ccedil;&otilde;es. <br />
            8.6. Caso seja rescindido o v&iacute;nculo de trabalho do Usu&aacute;rio junto ao Administrador, por
            qualquer
            raz&atilde;o, o Usu&aacute;rio n&atilde;o ter&aacute; mais permiss&atilde;o para usar o Aplicativo. O
            Usu&aacute;rio dever&aacute;, ent&atilde;o, excluir imediatamente sua conta do Aplicativo, imediatamente
            ap&oacute;s a rescis&atilde;o do seu v&iacute;nculo de trabalho junto ao Administrador, sem preju&iacute;zo
            da
            possibilidade de cancelamento do login do Usu&aacute;rio pelo Operador da Plataforma ou pelo Administrador.
            O
            Usu&aacute;rio ser&aacute; o &uacute;nico respons&aacute;vel pelas perdas incorridas pelo Operador da
            Plataforma
            no caso de uso cont&iacute;nuo ou em viola&ccedil;&atilde;o a esta cl&aacute;usula.
        </p>

        <p><b>9.&emsp;<span class="bold-underline">Obriga&ccedil;&otilde;es Espec&iacute;ficas do
                    Usu&aacute;rio</span></b><br />
            Ao aceitar estes Termos e Condi&ccedil;&otilde;es, o Usu&aacute;rio concorda, diretamente ou por meio de um
            intermedi&aacute;rio, com o seguinte, em rela&ccedil;&atilde;o ao Aplicativo:</p>

        <p>9.1. N&atilde;o usar o Aplicativo para a presta&ccedil;&atilde;o de servi&ccedil;os de sa&uacute;de ou
            atividades
            restritas ou sujeitas a regulamenta&ccedil;&atilde;o. Isso inclui, sem limita&ccedil;&atilde;o,
            diagn&oacute;stico, prescri&ccedil;&atilde;o para uso de drogas ou farmacovigil&acirc;ncia, telemedicina e
            tratamento de teleorienta&ccedil;&atilde;o.<br />
            9.2. N&atilde;o violar direitos de propriedade intelectual.<br />
            9.3. N&atilde;o realizar atividades que configurem pr&aacute;ticas anticompetitivas, concorr&ecirc;ncia
            desleal
            ou que violem direitos de defesa do consumidor. <br />
            9.4. N&atilde;o promover informa&ccedil;&otilde;es imprecisas ou enganosas. <br />
            9.5. N&atilde;o realizar atividades que constituam crime, especialmente relacionadas ao uso de tecnologia e
            informa&ccedil;&otilde;es e &agrave; substitui&ccedil;&atilde;o de terceiros ou fraude. <br />
            9.6. N&atilde;o realizar atividades que constituam a&ccedil;&otilde;es obscenas, ofensivas ou a
            invas&atilde;o
            da privacidade de terceiros. <br />
            9.7. N&atilde;o incorrer em cal&uacute;nia, abuso, ass&eacute;dio, amea&ccedil;as ou viola&ccedil;&otilde;es
            de
            qualquer liberdade p&uacute;blica ou direito civil ou humano ou qualquer outro direito de propriedade,
            privacidade e/ou publicidade de outros Usu&aacute;rios ou terceiros.<br />
            9.8. N&atilde;o violar os regulamentos de prote&ccedil;&atilde;o de dados pessoais. <br />
            9.9. N&atilde;o impor encargos irracionais ou desproporcionais &agrave; Administradora e/ou &agrave;
            infraestrutura do Aplicativo. O crit&eacute;rio para definir o que &eacute; considerado um &ocirc;nus
            irracional
            ou desproporcional fica inteiramente a crit&eacute;rio da Administradora ou do Operador da Plataforma
            (conforme
            definido abaixo). <br />
            9.10. N&atilde;o interferir ou tentar interferir no funcionamento correto do Aplicativo. <br />
            9.11. N&atilde;o duplicar, distribuir, enviar ou transferir de qualquer forma qualquer uma das
            Fun&ccedil;&otilde;es &agrave;s quais o Usu&aacute;rio possa ter acesso. <br />
            9.12. N&atilde;o usar este Aplicativo como meio para realizar atividades ilegais ou n&atilde;o autorizadas
            no
            Brasil e em qualquer outro pa&iacute;s. <br />
            9.13. Denunciar qualquer viola&ccedil;&atilde;o dos Termos e Condi&ccedil;&otilde;es por outros
            Usu&aacute;rios
            ou terceiros de que tenham conhecimento. Para tanto, os Usu&aacute;rio dever&atilde;o enviar e-mail para o
            endere&ccedil;o eletr&ocirc;nico [support@myalivius.com], para que o Operador da Plataforma tome as
            provid&ecirc;ncias que julgar cab&iacute;veis. <br />
            9.14. N&atilde;o carregar programas de computador ou outro material protegido por leis de propriedade
            intelectual para o Aplicativo, a menos que autorizado pelo propriet&aacute;rio dos referidos direitos.
            <br />
            9.15. N&atilde;o carregar para o Aplicativo arquivos que contenham v&iacute;rus ou qualquer outro arquivo ou
            programa de computa&ccedil;&atilde;o que possa afetar a programa&ccedil;&atilde;o do Aplicativo. <br />
            9.16. N&atilde;o armazenar qualquer tipo de an&uacute;ncio no Aplicativo, tendo em vista que as
            Fun&ccedil;&otilde;es n&atilde;o podem ser utilizadas para fins comerciais diferentes daqueles aqui
            declarados.<br />
            9.17. N&atilde;o compartilhar a senha e proteg&ecirc;-la para evitar o acesso ao perfil de usu&aacute;rio no
            Aplicativo por pessoas que n&atilde;o sejam o Usu&aacute;rio.</p>

        <p><b>10.&emsp;<span class="bold-underline">Obriga&ccedil;&otilde;es da Administradora</span></b><br />
            10.1. Manter o Aplicativo dispon&iacute;vel, de acordo com os termos acordados nestes Termos e
            Condi&ccedil;&otilde;es. <br />
            10.2. Se considerar necess&aacute;rio, prestar suporte e manuten&ccedil;&atilde;o do Aplicativo, o que
            poder&aacute; fazer por conta pr&oacute;pria ou por meio de terceiros. <br />
            10.3. Cumprir os regulamentos de prote&ccedil;&atilde;o de dados aplic&aacute;veis contidos na Lei Federal
            n&ordm; 13.709/2018, conforme alterada pela Lei Federal n&ordm; 13.853/2019, na Pol&iacute;tica de
            Privacidade
            do Aplicativo, bem como nos regulamentos que a modificam, adicionam ou revogam e na PPI.<br />
            10.4. O Administrador dever&aacute; informar o Operador da Plataforma, imediatamente, sobre quaisquer
            altera&ccedil;&otilde;es no status dos Usu&aacute;rios, incluindo, mas n&atilde;o se limitando a (i) o
            encerramento do v&iacute;nculo de trabalho do Usu&aacute;rio junto ao Administrador; e (ii) a perda das
            habilita&ccedil;&otilde;es legais necess&aacute;rias ao exerc&iacute;cio das atividades profissionais do
            Usu&aacute;rio. Qualquer ato ou omiss&atilde;o em informar o Operador da Plataforma sobre tais
            informa&ccedil;&otilde;es ser&aacute; considerado como viola&ccedil;&atilde;o destes Termos e
            Condi&ccedil;&otilde;es, e o Administrador ser&aacute; responsabilizado pelas perdas, custos e danos
            incorridos
            pelo Operador da Plataforma, devido ao ato ou omiss&atilde;o do Administrador</p>

        <p><b>11.&emsp;<span class="bold-underline">Declara&ccedil;&otilde;es e Garantias do
                    Usu&aacute;rio</span></b><br />
            Ao aceitar estes Termos e Condi&ccedil;&otilde;es, os Usu&aacute;rios afirmam, declaram e garantem que
            entendem,
            aceitam e reconhecem que:</p>

        <p>11.1. O Aplicativo n&atilde;o se destina a fins de pesquisa cient&iacute;fica e n&atilde;o ser&aacute; usado
            para
            medir a efic&aacute;cia ou seguran&ccedil;a de qualquer produto farmac&ecirc;utico ou tecnologia de
            sa&uacute;de. <br />
            11.2. O Aplicativo pode fornecer opini&otilde;es de especialistas consultados em um campo espec&iacute;fico
            para
            tanto. Tais informa&ccedil;&otilde;es representam t&atilde;o somente as opini&otilde;es dos respectivos
            peritos,
            que n&atilde;o s&atilde;o necessariamente as da Administradora.</p>

        <p>11.3. O Operador da Plataforma n&atilde;o garante que o conte&uacute;do e as informa&ccedil;&otilde;es
            dispon&iacute;veis no Aplicativo estejam livres de erros ou imprecis&otilde;es. Da mesma forma, os
            Usu&aacute;rios declaram que entendem e est&atilde;o satisfeitos com o Aplicativo e suas
            Fun&ccedil;&otilde;es.
            Portanto, o Usu&aacute;rio n&atilde;o pode invocar a repara&ccedil;&atilde;o por defeitos ocultos ou
            institui&ccedil;&otilde;es legais semelhantes durante a execu&ccedil;&atilde;o dos Termos e
            Condi&ccedil;&otilde;es ou ap&oacute;s isso. <br />
            11.4. O Operador da Plataforma n&atilde;o garante a disponibilidade, infalibilidade e continuidade do
            funcionamento do Aplicativo nem de suas Fun&ccedil;&otilde;es. Quando aplic&aacute;vel, a Administradora
            pode
            avisar sobre interrup&ccedil;&otilde;es na opera&ccedil;&atilde;o do Aplicativo e/ou das
            Fun&ccedil;&otilde;es.
            Caso o funcionamento do Aplicativo e/ou das Fun&ccedil;&otilde;es seja interrompido, a Administradora fica
            isenta de qualquer responsabilidade por danos ou preju&iacute;zos de qualquer natureza. <br />
            11.5. O Operador da Plataforma n&atilde;o garante que os conte&uacute;dos e informa&ccedil;&otilde;es
            dispon&iacute;veis no Aplicativo sejam suficientes e/ou &uacute;teis para o Usu&aacute;rio na
            presta&ccedil;&atilde;o da Assist&ecirc;ncia aos Pacientes. Da mesma forma, os conte&uacute;dos e
            Fun&ccedil;&otilde;es n&atilde;o constituem a presta&ccedil;&atilde;o de servi&ccedil;os m&eacute;dicos, de
            sa&uacute;de e afins de acordo com os regulamentos aplic&aacute;veis. <br />
            11.6. O Operador da Plataformatem o direito de rejeitar a entrada de qualquer pessoa, a qualquer momento e
            por
            qualquer motivo no Aplicativo. <br />
            11.7. A Administradora e o Operador da Plataforma (conforme definido abaixo) t&ecirc;m o direito de usar os
            Dados Pessoais (conforme definido abaixo) a seu pr&oacute;prio crit&eacute;rio, de acordo com estes Termos e
            Condi&ccedil;&otilde;es e o PPI. <br />
            11.8. O Operador da Plataforma (conforme definido abaixo) n&atilde;o &eacute; respons&aacute;vel pelas
            Fun&ccedil;&otilde;es e conte&uacute;dos do Aplicativo.<br />
            11.9. A Administradora tem o direito de usar os conte&uacute;dos fornecidos pelos Usu&aacute;rios de acordo
            com
            as disposi&ccedil;&otilde;es dos Termos e Condi&ccedil;&otilde;es. <br />
            11.10. O Operador da Plataforma pode conceder acesso a outros terceiros, permitindo que eles, se
            necess&aacute;rio, forne&ccedil;am suporte e manuten&ccedil;&atilde;o no Aplicativo. <br />
            11.11. O Operador da Plataforma n&atilde;o garante e, portanto, n&atilde;o &eacute; respons&aacute;vel pela
            presen&ccedil;a de v&iacute;rus ou outros elementos nocivos no Aplicativo ou que possam surgir como
            resultado do
            acesso do Usu&aacute;rio ao Aplicativo, que possam produzir altera&ccedil;&otilde;es nos sistemas de
            computador
            e/ou dispositivos dos Usu&aacute;rios ou de terceiros, ou nos arquivos armazenados nos referidos sistemas.
            <br />
            11.12. O Operador da Plataforma pode disponibilizar as informa&ccedil;&otilde;es inseridas pelos
            Usu&aacute;rios
            assim que os Usu&aacute;rios deixarem de usar o Aplicativo de acordo com as disposi&ccedil;&otilde;es dos
            Termos
            e Condi&ccedil;&otilde;es e do PPI, desde que isso n&atilde;o resulte em viola&ccedil;&atilde;o dos direitos
            dos
            Usu&aacute;rios.
        </p>

        <p><b>12.&emsp;<span class="bold-underline">Privacidade e Dados Pessoais</span></b> <br />
            12.1. O Usu&aacute;rio, ao utilizar o Aplicativo, por meio deste, fornece &agrave; Administradora e ao
            Operador
            da Plataforma, consentimento pr&eacute;vio, expresso e informado para quaisquer Dados Pessoais (conforme
            definido abaixo) que sejam coletados como consequ&ecirc;ncia do uso do Aplicativo, para serem usados,
            processados, transferidos, transmitidos e/ou compartilhados em n&iacute;vel nacional e internacional,
            inclusive
            para terceiros localizados em pa&iacute;ses que podem n&atilde;o fornecer n&iacute;veis adequados de
            prote&ccedil;&atilde;o (&ldquo;Processamento&rdquo;). Para mitigar os riscos, a Administradora e o Operador
            da
            Plataforma tomam medidas como an&aacute;lise de normas de privacidade e seguran&ccedil;a de terceiros e/ou
            execu&ccedil;&atilde;o de contratos adequados que incluam regulamentos espec&iacute;ficos sobre o tratamento
            de
            Dados Pessoais.<br />
            12.2. Os Dados Pessoais podem se referir &agrave;s seguintes informa&ccedil;&otilde;es do Usu&aacute;rio,
            permitindo que seja identificado e reconhecido como indiv&iacute;duo: dados de identifica&ccedil;&atilde;o
            (nome), dados demogr&aacute;ficos (idade, sexo), dados de contato (n&uacute;mero de telefone,
            endere&ccedil;o de
            e-mail, designa&ccedil;&atilde;o junto ao Administrador (opcional)), dados de
            registro/inscri&ccedil;&atilde;o
            do Usu&aacute;rio perante os &oacute;rg&atilde;os de classe competentes, informa&ccedil;&otilde;es sobre o
            uso
            do Aplicativo, vinculadas ao Usu&aacute;rio, que permitam sua identifica&ccedil;&atilde;o, bem como detalhes
            pessoais mencionados em conversas via bate-papo (&ldquo;Dados Pessoais&rdquo;). <br />
            12.3. O Processamento ocorrer&aacute; para as seguintes finalidades: (i) a troca de
            informa&ccedil;&otilde;es
            para presta&ccedil;&atilde;o de Assist&ecirc;ncia; (ii) envio de alertas e notifica&ccedil;&otilde;es de
            dentro
            do Aplicativo; (iii) envio e troca de mensagens instant&acirc;neas via bate-papo; (iv) hospedagem do
            Aplicativo
            em servidores externos; (v) Dados Pessoais consultados pela Administradora e/ou pelo Prestador de Cuidados,
            para
            fins das Funcionalidades e demais usos do Aplicativo, e (vi) a realiza&ccedil;&atilde;o de atividades de
            suporte
            e manuten&ccedil;&atilde;o pelo Operador da Plataforma (conforme definido abaixo). <br />
            12.4. Os Dados Pessoais ser&atilde;o coletados dos Usu&aacute;rios que registram e carregam
            informa&ccedil;&otilde;es no Aplicativo. <br />
            12.5. O Usu&aacute;rio como titular dos dados tem os seguintes direitos: (i) conhecer, atualizar, apagar e
            retificar Dados Pessoais; (ii) solicitar comprova&ccedil;&atilde;o da autoriza&ccedil;&atilde;o concedida
            &agrave; Administradora; (iii) mediante solicita&ccedil;&atilde;o pr&eacute;via, ser informado sobre o uso
            dos
            Dados Pessoais pela Administradora; (iv) apresentar reclama&ccedil;&atilde;o em raz&atilde;o de
            viola&ccedil;&otilde;es ao regime de prote&ccedil;&atilde;o de dados, perante autoridades competentes, e (v)
            revogar a autoriza&ccedil;&atilde;o para processar e/ou solicitar a exclus&atilde;o dos Dados Pessoais, na
            medida em que n&atilde;o haja obriga&ccedil;&otilde;es legais ou contratuais de permanecer no banco de
            dados.
            <br />
            12.6. Para saber mais sobre seus direitos, como exercer esses direitos e as finalidades e usos aos quais os
            Dados Pessoais estar&atilde;o sujeitos, os Usu&aacute;rios podem consultar o PPI do Operador da Plataforma,
            que
            est&aacute; dispon&iacute;vel em&nbsp;
            <a
                href="https://www.myalivius.com/privacy-policy-alivius-go">https://www.myalivius.com/privacy-policy-alivius-go</a>.
            Qualquer consulta, solicita&ccedil;&atilde;o, reivindica&ccedil;&atilde;o, reclama&ccedil;&atilde;o ou
            peti&ccedil;&atilde;o relacionada a Dados Pessoais pode ser enviada para
            [<span class="highlight">dataprivacy@svaaswellness.com</span>].<br />
            12.7. O Usu&aacute;rio declara, aceita e reconhece ter sido informado de que o consentimento para autorizar
            o
            tratamento de dados pessoais sens&iacute;veis &eacute; completamente opcional.
        </p>

        <p><b>13.&emsp;<span class="bold-underline">Operador de Plataforma</span></b></p>

        <p>13.1. A Svaas Wellness Limited (&ldquo;Operadora da Plataforma&rdquo;) &eacute; a propriet&aacute;ria e
            operadora
            do Aplicativo e visa fornecer solu&ccedil;&otilde;es digitais de sa&uacute;de que levem a uma melhor
            qualidade
            de vida e melhores resultados de sa&uacute;de no interesse de diversos grupos de consumidores e para
            diferentes
            localidades. Nesse contexto, o Operador da Plataforma exige Dados Pessoais para criar e fornecer
            benef&iacute;cios de sa&uacute;de em geral, bem como para otimizar as Fun&ccedil;&otilde;es do Aplicativo,
            de
            acordo com a Pol&iacute;tica de Privacidade do Aplicativo. <br />
            13.2. O Operador da Plataforma e qualquer outra empresa pertencente ou controlada pelo Operador da
            Plataforma
            (&ldquo;Afiliadas&rdquo;) s&atilde;o os titulares dos direitos de propriedade intelectual do Aplicativo. Os
            poderes, direitos e obriga&ccedil;&otilde;es contidos nas Cl&aacute;usulas 8, 9, 11, 15, 16, 17 e 18 destes
            Termos e Condi&ccedil;&otilde;es s&atilde;o entendidos como sendo acordados em favor do Operador da
            Plataforma e
            suas Afiliadas, que est&atilde;o diretamente habilitados a exigir o cumprimento destes direitos pelos
            Usu&aacute;rios.<br />
            13.3. Os Usu&aacute;rios e a Administradora concordam que o Operador da Plataforma e suas Afiliadas
            n&atilde;o
            ser&atilde;o respons&aacute;veis pelas Fun&ccedil;&otilde;es e conte&uacute;dos do Aplicativo.</p>

        <p><b>14.&emsp;<span class="bold-underline">Dados Tratados pelo Operador da Plataforma</span></b><br />
            14.1. Os usu&aacute;rios d&atilde;o seu consentimento pr&eacute;vio, expresso e informado para que o
            Operador da
            Plataforma colete e processe Dados Pessoais para otimizar as funcionalidades do Aplicativo, oferecer novas
            solu&ccedil;&otilde;es digitais para a &aacute;rea da sa&uacute;de e para opera&ccedil;&otilde;es de
            tratamento.<br />
            14.2. O Processamento do Operador da Plataforma ocorrer&aacute; para as seguintes finalidades: (i) cumprir
            suas
            obriga&ccedil;&otilde;es como contratante do Administrador; (ii) hospedar a Plataforma em servidores
            externos,
            inclusive por terceiros localizados em pa&iacute;ses que n&atilde;o ofere&ccedil;am n&iacute;veis adequados
            de
            prote&ccedil;&atilde;o; (iii) prestar, direta ou indiretamente, suporte operacional, corretivo ou preventivo
            em
            qualquer incidente reportado pelo Usu&aacute;rio ou diagnosticado pelo provedor de hospedagem; (iv)
            solucionar
            problemas t&eacute;cnicos, erros e falhas do Aplicativo; (v) identificar oportunidades para aprimorar o
            servi&ccedil;o, melhorar os recursos e otimizar a experi&ecirc;ncia do usu&aacute;rio; (vi) adaptar
            recursos,
            interfaces e funcionalidades de forma personalizada; (vii) analisar tend&ecirc;ncias de uso para planejar a
            escalabilidade e (viii) informar decis&otilde;es relacionadas ao desenvolvimento de novos recursos ou
            aprimoramentos dos existentes.<br />
            14.3. O Usu&aacute;rio, na qualidade de titular dos dados, tem os seguintes direitos: (i) conhecer,
            atualizar,
            excluir e retificar os Dados Pessoais; (ii) solicitar comprova&ccedil;&atilde;o da autoriza&ccedil;&atilde;o
            concedida ao Administrador, salvo se n&atilde;o for exigido por lei; (iii) mediante
            solicita&ccedil;&atilde;o,
            ser informado sobre o uso dos Dados Pessoais pelo Operador da Plataforma; (iv) apresentar
            reclama&ccedil;&atilde;o por viola&ccedil;&otilde;es do regime de prote&ccedil;&atilde;o de dados, perante
            as
            autoridades competentes, e (v) revogar a autoriza&ccedil;&atilde;o para processamento e/ou solicitar a
            exclus&atilde;o dos Dados Pessoais, na medida em que n&atilde;o haja obriga&ccedil;&otilde;es legais ou
            contratuais para sua perman&ecirc;ncia no banco de dados.<br />
            14.4. O Usu&aacute;rio declara, aceita e reconhece ter sido informado de que o consentimento para autorizar
            o
            processamento de dados pessoais sens&iacute;veis &eacute; totalmente opcional.<br />
            14.5. Sem preju&iacute;zo do exposto acima, para conhecer seus direitos, as formas de execu&ccedil;&atilde;o
            e
            as finalidades e utiliza&ccedil;&otilde;es a que os Dados Pessoais estar&atilde;o sujeitos, os
            Usu&aacute;rios
            poder&atilde;o consultar a Pol&iacute;tica de Tratamento de Informa&ccedil;&otilde;es do Operador da
            Plataforma,
            dispon&iacute;vel em&nbsp; <a
                href="https://www.myalivius.com/privacy-policy-alivius-go">https://www.myalivius.com/privacy-policy-alivius-go</a>.
            Qualquer
            consulta, solicita&ccedil;&atilde;o, reivindica&ccedil;&atilde;o, reclama&ccedil;&atilde;o ou
            peti&ccedil;&atilde;o relacionada a Dados Pessoais tratados pelo Operador da Plataforma poder&aacute; ser
            enviada a [<span class="highlight">dataprivacy@svaaswellness.com</span>].</p>

        <p><b>15.&emsp;<span class="bold-underline">Limita&ccedil;&atilde;o de Responsabilidade e N&atilde;o
                    Declara&ccedil;&atilde;o e
                    Garantias</span> </b><br />
            15.1. O Operador da Plataforma n&atilde;o &eacute; respons&aacute;vel pelo uso do Aplicativo pelo
            Usu&aacute;rio. O Operador da Plataforma n&atilde;o assume responsabilidade no caso de a Assist&ecirc;ncia
            implicar ou envolver, direta ou indiretamente, qualquer atividade que preveja a presta&ccedil;&atilde;o de
            servi&ccedil;os de sa&uacute;de ou atividades que sejam restritas ou sujeitas a regulamenta&ccedil;&atilde;o
            espec&iacute;fica. Isso inclui, sem limita&ccedil;&atilde;o, diagn&oacute;stico, prescri&ccedil;&atilde;o
            para o
            uso de medicamentos ou farmacovigil&acirc;ncia, telemedicina e tratamento de teleorienta&ccedil;&atilde;o
            atrav&eacute;s do Aplicativo. <br />
            15.2. Os conte&uacute;dos, software, Fun&ccedil;&otilde;es e outras informa&ccedil;&otilde;es
            dispon&iacute;veis
            no Aplicativo podem conter erros ou imprecis&otilde;es. O Operador da Plataforma n&atilde;o garante a
            exatid&atilde;o destes ou de outras informa&ccedil;&otilde;es dispon&iacute;veis no Aplicativo e n&atilde;o
            assume qualquer responsabilidade por imprecis&otilde;es ou erros a este respeito.<br />
            15.3. Por nenhuma raz&atilde;o e em nenhuma hip&oacute;tese o Operador da Plataforma (seus conselheiros,
            diretores e filiais) ser&aacute; respons&aacute;vel por danos diretos, indiretos, punitivos, incidentais,
            especiais, consequenciais, compensat&oacute;rios ou qualquer outro tipo de dano derivado ou relacionado ao
            acesso ou uso pelo Usu&aacute;rio do Aplicativo, independentemente da teoria de responsabilidade que embasa
            as
            alega&ccedil;&otilde;es. <br />
            15.4. O Operador da Plataforma n&atilde;o assume qualquer tipo de responsabilidade nem garante de forma
            alguma a
            qualidade, validade, efic&aacute;cia e veracidade dos conte&uacute;dos e nem das Fun&ccedil;&otilde;es do
            Aplicativo. <br />
            15.5. Os limites e isen&ccedil;&otilde;es de responsabilidade aqui contidos nestes Termos e
            Condi&ccedil;&otilde;es continuar&atilde;o e ser&atilde;o aplic&aacute;veis no caso de ser determinado que
            qualquer uma das disposi&ccedil;&otilde;es em particular n&atilde;o cumpre com seu prop&oacute;sito
            essencial.
            Os limites e isen&ccedil;&otilde;es de responsabilidade s&atilde;o estabelecidos em favor do Operador da
            Plataforma, sem preju&iacute;zo do disposto na Cl&aacute;usula 19 destes Termos e Condi&ccedil;&otilde;es.
        </p>

        <p><b>16.&emsp;<span class="bold-underline">Indeniza&ccedil;&atilde;o </span></b><br />
            16.1. O Usu&aacute;rio deve defender, compensar e manter isenta de danos a Administradora, suas Afiliadas e
            cada
            um de seus funcion&aacute;rios; o Operador da Plataforma, suas Afiliadas e cada um de seus
            funcion&aacute;rios,
            contratados, diretores, provedores e representantes, de todas as responsabilidades,
            reivindica&ccedil;&otilde;es
            e despesas, assim como todos os custos relacionados, incluindo, mas sem se limitar, honor&aacute;rios
            advocat&iacute;cios que surjam ou estejam relacionados ao uso indevido ou acesso ao Aplicativo, ou qualquer
            outro uso das informa&ccedil;&otilde;es inseridas no Aplicativo, pelo Usu&aacute;rio, ou a
            viola&ccedil;&atilde;o dos Termos e Condi&ccedil;&otilde;es ou viola&ccedil;&atilde;o pelo Usu&aacute;rio ou
            qualquer terceiro que use sua conta, de qualquer propriedade intelectual ou outro direito de qualquer pessoa
            ou
            entidade. A Administradora reserva-se o direito de assumir a defesa e o controle exclusivos de qualquer
            assunto
            sujeito a compensa&ccedil;&atilde;o pelo Usu&aacute;rio. Nesse caso, o Usu&aacute;rio ajudar&aacute; e
            cooperar&aacute; com a Administradora em sua defesa.</p>

        <p><b>17.&emsp;<span class="bold-underline"> Rescis&atilde;o</span></b><br />
            17.1. Estes Termos e Condi&ccedil;&otilde;es, com suas modifica&ccedil;&otilde;es, altera&ccedil;&otilde;es
            e
            exclus&otilde;es, tornam-se vinculantes para o Usu&aacute;rio quando o Usu&aacute;rio baixar ou se registrar
            no
            Aplicativo e continuar&atilde;o em vigor at&eacute; que sejam rescindidos pelo Usu&aacute;rio ou pela
            Administradora ou pelo Operador da Plataforma, de acordo com os termos aqui contidos. N&atilde;o obstante o
            acima exposto, as restri&ccedil;&otilde;es, obriga&ccedil;&otilde;es e riscos assumidos pelo Usu&aacute;rio
            ap&oacute;s a aceita&ccedil;&atilde;o dos Termos e Condi&ccedil;&otilde;es, bem como as
            limita&ccedil;&otilde;es
            de responsabilidade e compensa&ccedil;&otilde;es estabelecidas pela Administradora e palo Operador da
            Plataforma
            nestes Termos e Condi&ccedil;&otilde;es sobreviver&atilde;o &agrave; rescis&atilde;o dos Termos e
            Condi&ccedil;&otilde;es. <br />
            17.2. As Partes reservam-se o direito de encerrar o Aplicativo e/ou rescindir os Termos e
            Condi&ccedil;&otilde;es, total ou parcialmente, a seu exclusivo crit&eacute;rio. O encerramento pode
            resultar na
            destrui&ccedil;&atilde;o total de todas as informa&ccedil;&otilde;es relacionadas &agrave; conta do
            Usu&aacute;rio. Consulte a Pol&iacute;tica de Privacidade para compreender as condi&ccedil;&otilde;es de
            reten&ccedil;&atilde;o dos seus dados ap&oacute;s o encerramento da sua conta.<br />
            17.3. Qualquer tipo de responsabilidade que os Usu&aacute;rios possam atribuir ao Operador da Plataforma
            como
            resultado da rescis&atilde;o destes Termos e Condi&ccedil;&otilde;es e do Aplicativo foi expressamente
            exclu&iacute;do.</p>

        <p><b>18.&emsp;<span class="bold-underline"> Direitos de Propriedade e Direitos de Propriedade Intelectual da
                    Administradora e de Terceiros </span></b><br />
            18.1. Todos os elementos e informa&ccedil;&otilde;es deste Aplicativo e das suas Fun&ccedil;&otilde;es,
            incluindo texto, processos, m&eacute;todos, segredos de empresa, know-how, t&eacute;cnicas, formas, imagens,
            m&uacute;sica, marcas, logotipos, emblemas, nomes comerciais, sons, gr&aacute;ficos, v&iacute;deos,
            anima&ccedil;&atilde;o e outros materiais e ferramentas sujeitos a prote&ccedil;&atilde;o atrav&eacute;s de
            propriedade intelectual, s&atilde;o e continuar&atilde;o a ser propriedade do seu respetivo
            propriet&aacute;rio,
            seja a Administradora, o Operador da Plataforma, titulares de licen&ccedil;as, benefici&aacute;rios e, em
            geral,
            quaisquer terceiros. <br />
            18.2. Qualquer uso n&atilde;o autorizado dos elementos e informa&ccedil;&otilde;es deste Aplicativo que
            viole a
            propriedade e os direitos de propriedade intelectual da Administradora, do Operador da Plataforma, de seus
            contratados, titulares de licen&ccedil;as, benefici&aacute;rios e, em geral, de terceiros, poder&aacute;
            implicar o in&iacute;cio de a&ccedil;&otilde;es legais correspondentes pelos titulares de tais direitos.
            <br />
            18.3. O acesso e o uso do Aplicativo n&atilde;o implicar&atilde;o, de forma alguma, a transfer&ecirc;ncia ou
            recusa de qualquer licen&ccedil;a, concess&atilde;o ou direito de uso de quaisquer textos, processos,
            m&eacute;todos, segredos de empresa, know-how, t&eacute;cnicas, formas, imagens, m&uacute;sicas, marcas,
            logotipos, emblemas, nomes comerciais, sons, gr&aacute;ficos, v&iacute;deos, anima&ccedil;&otilde;es e
            outros
            materiais e ferramentas sujeitos &agrave; prote&ccedil;&atilde;o por meio de direitos de propriedade
            intelectual, diferentes das disposi&ccedil;&otilde;es declaradas precisamente neste documento. <br />
            18.4. &Eacute; proibida a cria&ccedil;&atilde;o de p&aacute;ginas da web, gateways da Internet, aplicativos,
            documentos comerciais, eletr&ocirc;nicos ou programas de computador ou aplicativos de
            computa&ccedil;&atilde;o
            de qualquer tipo que contenham hiperlinks ou marcas que redirecionem o navegador para qualquer parte do
            Aplicativo. <br />
            18.5. Fica expressamente exclu&iacute;do qualquer tipo de responsabilidade que o Usu&aacute;rio possa
            atribuir
            &agrave; Administradora e ao Operador da Plataforma em decorr&ecirc;ncia da viola&ccedil;&atilde;o dos
            direitos
            de outros usu&aacute;rios ou de terceiros aos seus direitos de propriedade intelectual. <br />
            18.6. A Administradora e o Operador da Plataforma n&atilde;o assumem qualquer responsabilidade por
            quest&otilde;es de propriedade intelectual derivadas do conte&uacute;do dos Usu&aacute;rios ou de terceiros
            e
            das informa&ccedil;&otilde;es fornecidas pelos Usu&aacute;rios ou por terceiros nos produtos ou
            servi&ccedil;os
            da Administradora e do Operador da Plataforma.
        </p>

        <p><b>19.&emsp;<span class="bold-underline">Viola&ccedil;&atilde;o dos Termos e Condi&ccedil;&otilde;es
                </span></b><br />
            19.1. Qualquer viola&ccedil;&atilde;o dos Termos e Condi&ccedil;&otilde;es ou qualquer
            reclama&ccedil;&atilde;o
            ou informa&ccedil;&atilde;o que o Operador da Plataforma receba em rela&ccedil;&atilde;o &agrave;
            viola&ccedil;&atilde;o, abuso ou uso inadequado destes Termos e Condi&ccedil;&otilde;es, do Aplicativo e/ou
            de
            suas Fun&ccedil;&otilde;es, poder&aacute; ser investigada pelo Operador da Plataforma ou por terceiros,
            oportunidade em que ser&atilde;o tomadas as medidas necess&aacute;rias para iniciar todas as
            a&ccedil;&otilde;es
            legais e extrajudiciais contra os agentes investigados, com o intuito de encerrar a conduta n&atilde;o
            conforme
            e, conforme aplic&aacute;vel, obter os reparos e indeniza&ccedil;&otilde;es aplic&aacute;veis de acordo com
            as
            leis em vigor. A viola&ccedil;&atilde;o destes Termos e Condi&ccedil;&otilde;es pode resultar na
            suspens&atilde;o ou cancelamento definitivo de sua conta e na correspondente responsabilidade civil ou
            criminal.
            <br />
            19.2. Se o Usu&aacute;rio questionar a legalidade de suas a&ccedil;&otilde;es durante suas atividades no
            Aplicativo e/ou o uso de suas Fun&ccedil;&otilde;es, n&atilde;o hesite em entrar em contato com o Operador
            da
            Plataforma com anteced&ecirc;ncia. <br />
            19.3. Se o Usu&aacute;rio considerar que os seus direitos foram violados por terceiros ou pelo Operador da
            Plataforma Administradora ou que a sua conta foi suspensa ou cancelada por engano ou porque a sua identidade
            foi
            confundida, n&atilde;o hesite em contatar-nos.
        </p>

        <p><b>20.&emsp;<span class="bold-underline">Sites e/ou Aplicativos de Terceiros</span></b><br />
            20.1. Os sites vinculados ao Aplicativo n&atilde;o s&atilde;o de propriedade do Operador da Plataforma nem
            s&atilde;o operados, controlados ou administrados pelo Operador da Plataforma e, portanto, o Operador da
            Plataforma est&aacute; isento de qualquer tipo de responsabilidade quanto ao conte&uacute;do,
            pr&aacute;ticas,
            pol&iacute;ticas, seguran&ccedil;a, servi&ccedil;os, disponibilidade, cumprimento de termos e
            condi&ccedil;&otilde;es de uso ou pol&iacute;ticas de privacidade relacionados a estes sites. <br />
            20.2. Qualquer link feito no Aplicativo para sites de terceiros n&atilde;o constitui patroc&iacute;nio,
            prote&ccedil;&atilde;o, defesa, garantia ou suporte de qualquer tipo do Operador da Plataforma em
            rela&ccedil;&atilde;o ao conte&uacute;do, pr&aacute;ticas, pol&iacute;ticas, seguran&ccedil;a,
            servi&ccedil;os,
            disponibilidade, conformidade com termos e condi&ccedil;&otilde;es de uso ou pol&iacute;ticas de privacidade
            destes sites. <br />
            20.3. O Usu&aacute;rio &eacute; respons&aacute;vel pelo acesso e uso de sites de terceiros que estejam
            vinculados ao Aplicativo e/ou &agrave;s Fun&ccedil;&otilde;es. <br />
            20.4. Fica expressamente exclu&iacute;do qualquer tipo de responsabilidade que os Usu&aacute;rios possam
            atribuir ao Operador da Plataforma em decorr&ecirc;ncia de danos relacionados ao uso de gateways,
            servi&ccedil;os, produtos ou conte&uacute;dos que estejam dispon&iacute;veis por meio de sites de terceiros
            vinculados ao Aplicativo.</p>

        <p><b>21.&emsp;<span class="bold-underline">Rela&ccedil;&atilde;o entre o Operador da Plataforma e o
                    Usu&aacute;rio
                </span></b><br />
            21.1. O Operador da Plataforma n&atilde;o possui qualquer tipo de rela&ccedil;&atilde;o jur&iacute;dica com
            o
            Usu&aacute;rio diferente daquela descrita ao longo destes Termos e Condi&ccedil;&otilde;es. Portanto, o
            Operador
            da Plataforma n&atilde;o &eacute; obrigado a adotar medidas em rela&ccedil;&atilde;o ao seguinte:<br />
            o Usu&aacute;rios que podem acessar o Aplicativo e seu uso de suas Fun&ccedil;&otilde;es. <br />
            o A qualidade, validade, efic&aacute;cia e veracidade dos conte&uacute;dos oferecidos no Aplicativo. <br />
            o Os efeitos que certos tipos de conte&uacute;do podem ter sobre os Usu&aacute;rios. <br />
            o A interpreta&ccedil;&atilde;o que os Usu&aacute;rios fazem do conte&uacute;do e/ou Fun&ccedil;&otilde;es.
        </p>

        <p>21.2. O Operador da Plataforma n&atilde;o garante a autenticidade ou veracidade das informa&ccedil;&otilde;es
            fornecidas no Aplicativo por ou por outros Usu&aacute;rios sobre o mesmo, em rela&ccedil;&atilde;o &agrave;
            qualidade, validade, efic&aacute;cia e veracidade dos conte&uacute;dos oferecidos.</p>

        <p><b>22.&emsp;<span class="bold-underline">Lei Aplic&aacute;vel </span></b><br />
            22.1. Estes Termos e Condi&ccedil;&otilde;es ser&atilde;o regidos e interpretados de acordo com as leis
            da
            Rep&uacute;blica Federativa do Brasil.</p>

        <p><b>23.&emsp;<span class="bold-underline">Independ&ecirc;ncia das Cl&aacute;usulas </span></b><br />
            23.1. Se qualquer um dos termos, disposi&ccedil;&otilde;es ou condi&ccedil;&otilde;es destes Termos e
            Condi&ccedil;&otilde;es ou a sua aplica&ccedil;&atilde;o a qualquer circunst&acirc;ncia for considerada
            inv&aacute;lida ou inexequ&iacute;vel, a validade ou exequibilidade do restante destes Termos e
            Condi&ccedil;&otilde;es n&atilde;o ser&aacute; afetada, e cada um dos outros termos,
            disposi&ccedil;&otilde;es e
            condi&ccedil;&otilde;es destes Termos e Condi&ccedil;&otilde;es ser&aacute; v&aacute;lido e exequ&iacute;vel
            em
            toda a medida permitida por lei.</p>

        <p><b>24.&emsp;<span class="bold-underline">Contato</span></b><br />
            24.1. Se os Usu&aacute;rios tiverem alguma d&uacute;vida ou preocupa&ccedil;&atilde;o sobre os Termos e
            Condi&ccedil;&otilde;es, eles podem entrar em contato com o Operador da Plataforma por e-mail
            [<span class="highlight">dataprivacy@svaaswellness.com</span>]<br />
        </p>
    </div>
    )
}

export default TermsAndConditionsAliviusGo;