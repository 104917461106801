import React, { useEffect } from 'react';
import './PrivacyPolicy.css'

const PrivacyPolicy = () => {
        useEffect(() => {
                window.scrollTo(0, 0); // Scroll to the top of the page
              }, []);
    return (
        <div class="pp-container">
                <h2 class="center"><strong><span class="underline">POL&Iacute;TICA
                                        DE PRIVACIDADE DO ALIVIUS</span> </strong></h2>
                <h4>1. &nbsp;&nbsp;&nbsp;&nbsp; Introdu&ccedil;&atilde;o</h4>
                <p>A SVAAS Wellness Private Limited (&ldquo;SVAAS&rdquo;), uma empresa existente e registrada de acordo
                        com as leis da &Iacute;ndia, com sede em 8-2-337, Road No. 3, Banjara Hills, Hyderabad- 500 034,
                        Telangana, &Iacute;ndia, e cada uma de suas afiliadas e entidades subsidi&aacute;rias
                        (coletivamente &ldquo;SVASS&rdquo;) est&atilde;o comprometidas em manter a precis&atilde;o,
                        confidencialidade e seguran&ccedil;a dos dados pessoais dos usu&aacute;rios do&nbsp; aplicativo
                        Alivius (doravante &ldquo;Aplicativo&rdquo;). Esta Pol&iacute;tica de Privacidade do Alivius
                        (&ldquo;Pol&iacute;tica&rdquo;) foi preparada para explicar aos usu&aacute;rios do Aplicativo
                        quais dados pessoais s&atilde;o coletados e como s&atilde;o coletados, tratados e divulgados,
                        assim como outras informa&ccedil;&otilde;es relacionadas aos direitos destes usu&aacute;rios, em
                        conex&atilde;o com o uso do Aplicativo.&nbsp;</p>
                <p>Antes de registrar sua conta no Aplicativo, certifique-se de que leu, entendeu e aceitou todas as
                        informa&ccedil;&otilde;es desta Pol&iacute;tica. Caso voc&ecirc; n&atilde;o concorde com
                        qualquer parte desta Pol&iacute;tica, n&atilde;o registre uma Conta (conforme
                        defini&ccedil;&atilde;o abaixo), nem acesse o Aplicativo. A conclus&atilde;o do registro da sua
                        Conta ou a continua&ccedil;&atilde;o do uso do Aplicativo indicar&aacute; sua
                        aceita&ccedil;&atilde;o dos termos desta Pol&iacute;tica. Devido ao fato de que nossa
                        Pol&iacute;tica pode mudar a qualquer momento, recomendamos que voc&ecirc; revise esta
                        Pol&iacute;tica de tempos em tempos para verificar se h&aacute;
                        atualiza&ccedil;&otilde;es.&nbsp;&nbsp;</p>
                <p>Esta Pol&iacute;tica de Privacidade ser&aacute; aplic&aacute;vel aos Usu&aacute;rios do Alivius. Ao
                        acessar o Alivius ou utilizar qualquer um dos servi&ccedil;os nele inclu&iacute;dos, o
                        usu&aacute;rio reconhece que leu e compreendeu os termos e condi&ccedil;&otilde;es de uso, que
                        incluem esta Pol&iacute;tica de Privacidade, e concorda com os termos.<strong>&nbsp;</strong>
                </p>
                <h4>2. &nbsp;&nbsp;&nbsp;&nbsp; Defini&ccedil;&otilde;es</h4>
                <p><strong>&ldquo;Conta&rdquo;:</strong> Registro de conta que permite ao Usu&aacute;rio acessar e usar
                        o Aplicativo.</p>
                <p><strong>&ldquo;Dados Pessoais&rdquo;:</strong> Significa qualquer informa&ccedil;&atilde;o de
                        identifica&ccedil;&atilde;o relacionada a um Usu&aacute;rio do Aplicativo, incluindo, mas
                        n&atilde;o se limitando, nome, telefone, e-mail e data de nascimento;
                        <strong>&ldquo;Pol&iacute;tica&rdquo;:</strong> refere-se a esta Pol&iacute;tica de Privacidade
                        do Alivius.
                </p>
                <p><strong>&ldquo;Usu&aacute;rio&rdquo; ou &ldquo;Voc&ecirc;&rdquo;:</strong> significa qualquer pessoa
                        f&iacute;sica que fa&ccedil;a uso do Aplicativo, a qualquer t&iacute;tulo, em especial, os
                        profissionais de sa&uacute;de autorizados, enfermeiras e prestadores de cuidados das entidades
                        dedicadas aos servi&ccedil;os de sa&uacute;de.</p>
                <p><strong>Aplicativo:</strong> Solu&ccedil;&atilde;o de software com base na Internet,
                        compat&iacute;vel com os sistemas operacionais Android e iOS, fornecida via aplicativo
                        m&oacute;vel ao Usu&aacute;rio, como um aplicativo de propriedade da SVAAS, usado para melhorar
                        a comunica&ccedil;&atilde;o entre as entidades dedicadas aos servi&ccedil;os de sa&uacute;de e
                        seus pacientes.<strong>&nbsp;</strong></p>
                <h4>3. &nbsp;&nbsp;&nbsp;&nbsp; Legisla&ccedil;&atilde;o aplic&aacute;vel</h4>
                <p>Aplica-se &agrave; presente Pol&iacute;tica a legisla&ccedil;&atilde;o brasileira, incluindo, mas
                        n&atilde;o se limitando: (i) as Leis Federais n&ordm; 13.709/2018, conforme alterada pela Lei
                        n&ordm; 13.853/2019, e n&ordm; 12.965/2014 (&ldquo;doravante LGPD&rdquo;); (ii) o Decreto
                        n&ordm; 8.771/2016; (iii) a Constitui&ccedil;&atilde;o Federal Brasileira; (iv) o C&oacute;digo
                        Civil Brasileiro, conforme alterado de tempos em tempos; e qualquer futura
                        legisla&ccedil;&atilde;o brasileira ou regulamenta&ccedil;&atilde;o aplic&aacute;vel emitida
                        pela Autoridade Brasileira de Prote&ccedil;&atilde;o de Dados (&ldquo;ANPD&rdquo;) e/ou pela
                        Ag&ecirc;ncia Nacional de Vigil&acirc;ncia Sanit&aacute;ria (&ldquo;ANVISA&rdquo;), ou ainda
                        outra autoridade governamental competente.&nbsp;&nbsp;</p>
                <h4>4. &nbsp;&nbsp;&nbsp;&nbsp; Princ&iacute;pios&nbsp;</h4>
                <p>O tratamento dos Dados Pessoais fornecidos pelo Usu&aacute;rio no &acirc;mbito da
                        utiliza&ccedil;&atilde;o do Aplicativo, ser&aacute; baseado, dentre outros, nos
                        princ&iacute;pios da finalidade, essencialidade e transpar&ecirc;ncia.&nbsp;</p>
                <p>A SVAAS est&aacute; comprometida em proteger a privacidade dos Usu&aacute;rios e, neste sentido, toma
                        e as medidas razo&aacute;veis para proteger a privacidade e a confidencialidade dos Dados
                        Pessoais do Usu&aacute;rio, conforme fornecidos por estes, no &acirc;mbito da
                        utiliza&ccedil;&atilde;o do Aplicativo.&nbsp;</p>
                <h4>5. &nbsp;&nbsp;&nbsp;&nbsp; Tipos de Dados Pessoais Coletados</h4>
                <p>N&oacute;s coletamos os Dados Pessoais que o Usu&aacute;rio fornecer voluntariamente por meio do
                        Aplicativo, incluindo:&nbsp;</p>
                <ul>
                        <li>Dados de identifica&ccedil;&atilde;o, como nome,&nbsp; idade,&nbsp; sexo, n&uacute;mero de
                                telefone, endere&ccedil;o de e-mail, designa&ccedil;&atilde;o do Usu&aacute;rio junto ao
                                Administrador, conforme definido nos Termos e Condi&ccedil;&otilde;es de Uso de Alivius,
                                e conforme aplic&aacute;vel, n&uacute;mero de registro/inscri&ccedil;&atilde;o do
                                Usu&aacute;rio junto ao seu respectivo &oacute;rg&atilde;o de classe.</li>
                        <li>Dados relacionados ao registro de som e imagem em conex&atilde;o com os servi&ccedil;os de
                                sa&uacute;de que est&atilde;o sendo prestados ao Paciente com a
                                participa&ccedil;&atilde;o do Usu&aacute;rio.</li>
                        <li>Dados Pessoais relacionados &agrave; atividade de navega&ccedil;&atilde;o do Usu&aacute;rio
                                durante o uso do Aplicativo, incluindo, conforme aplic&aacute;veis, endere&ccedil;o
                                IP,&nbsp; cookies, dura&ccedil;&atilde;o do uso, data e hora de acesso, idioma
                                utilizado, localiza&ccedil;&atilde;o geogr&aacute;fica.</li>
                        <li>Outros Dados Pessoais e informa&ccedil;&otilde;es fornecidas pelo Usu&aacute;rio na
                                utiliza&ccedil;&atilde;o das funcionalidades do Aplicativo e quaisquer outras
                                informa&ccedil;&otilde;es fornecidas pelo Usu&aacute;rio que n&atilde;o revelem sua
                                identidade espec&iacute;fica.&nbsp;</li>
                </ul>
                <p>Devido ao fato de que o Aplicativo processa apenas dados selecionados pelo Usu&aacute;rio, a SVAAS
                        n&atilde;o atua isoladamente como controladora dos Dados Pessoais processados por meio do
                        Aplicativo. A Administradora (conforme definido nos Termos e Condi&ccedil;&otilde;es do
                        Aplicativo) tamb&eacute;m atuar&aacute; conjuntamente como Controlador de Dados das
                        informa&ccedil;&otilde;es processadas por meio do Aplicativo por seu Usu&aacute;rio.&nbsp;</p>
                <p>Portanto, o Usu&aacute;rio, no &acirc;mbito do Aplicativo, assume a condi&ccedil;&atilde;o de
                        controlador, tendo autonomia em rela&ccedil;&atilde;o ao tratamento de Dados Pessoais e Dados
                        Pessoais Sens&iacute;veis, podendo escolher a finalidade para a qual s&atilde;o tratados, a
                        categoria de dados a serem coletados, por quanto tempo tais dados s&atilde;o retidos, e outras
                        quest&otilde;es diretamente relacionadas ao tratamento de Dados Pessoais e Dados Pessoais
                        Sens&iacute;veis.&nbsp;</p>
                <p>Observe que os dados coletados e processados pelo nosso Aplicativo s&atilde;o controlados pela SVVAS
                        e pela Administradora, que os usam, divulgam e protegem, de acordo com suas respectivas
                        pol&iacute;ticas de privacidade e prote&ccedil;&atilde;o de dados.&nbsp;&nbsp;</p>
                <p>N&atilde;o obstante, a SVAAS ser&aacute; controladora de dados apenas para os fins de: (i) hospedar o
                        Aplicativo em servidores externos; (ii) prestar servi&ccedil;os de suporte e
                        manuten&ccedil;&atilde;o aos Usu&aacute;rios; (iii) abordar problemas t&eacute;cnicos, bugs e
                        falhas no Aplicativo; (iv) identificar oportunidades para aprimorar recursos e otimizar a
                        experi&ecirc;ncia do Usu&aacute;rio; (v) adaptar recursos, interfaces e funcionalidades de forma
                        personalizada; e (vi) analisar tend&ecirc;ncias de uso para planejar a escalabilidade.&nbsp;</p>
                <p class="align-left">Como processador de dados, embora a SVVAS n&atilde;o controle os dados que
                        s&atilde;o
                        coletados diretamente do Usu&aacute;rio, a SVVAS adota v&aacute;rias pr&aacute;ticas de
                        seguran&ccedil;a para garantir que as informa&ccedil;&otilde;es coletadas em nosso Aplicativo
                        sejam processadas de maneira segura de acordo com a legisla&ccedil;&atilde;o
                        aplic&aacute;vel.&nbsp;&nbsp;</p>
                <p>Para saber mais sobre como coletamos cookies durante seu acesso ao nosso Aplicativo consulte nossa
                        cookie policy.&nbsp;<strong>&nbsp;</strong></p>
                <h4>6. &nbsp;&nbsp;&nbsp;&nbsp; Quando coletamos os seus Dados Pessoais</h4>
                <ul>
                        <li>Ap&oacute;s a cria&ccedil;&atilde;o de uma conta pelo Usu&aacute;rio;</li>
                        <li>Quando o Usu&aacute;rio carrega quaisquer dados diretamente no Aplicativo;&nbsp;</li>
                        <li>Enquanto o Usu&aacute;rio estiver usando o Aplicativo e/ou os produtos e alguns dos
                                servi&ccedil;os disponibilizados no Aplicativo;</li>
                        <li>Quando obtemos informa&ccedil;&otilde;es sobre seu uso do Aplicativo;</li>
                        <li>Quando o Usu&aacute;rio usar o chat dispon&iacute;vel no Aplicativo;</li>
                        <li>Quando o Usu&aacute;rio se cadastrar para receber e-mails, alertas ou outras
                                informa&ccedil;&otilde;es; e&nbsp;</li>
                        <li>Quando recebemos informa&ccedil;&otilde;es de terceiros.&nbsp;</li>
                </ul>
                <p>O Usu&aacute;rio que preencher Dados Pessoais no Aplicativo,conforme o caso, &eacute; exclusivamente
                        respons&aacute;vel pela precis&atilde;o, clareza, relev&acirc;ncia, atualiza&ccedil;&atilde;o e
                        integridade dos Dados Pessoais fornecidos.&nbsp;</p>
                <p>As informa&ccedil;&otilde;es enviadas devem ser mantidas atualizadas para garantir que os
                        servi&ccedil;os permane&ccedil;am relevantes e cheguem at&eacute; Voc&ecirc;.&nbsp;</p>
                <h4>7. &nbsp;&nbsp;&nbsp;&nbsp; Uso das informa&ccedil;&otilde;es</h4>
                <p>Ao usar este Aplicativo e/ou nossos servi&ccedil;os, Voc&ecirc; reconhece que nos autorizou a usar
                        seus Dados Pessoais para os seguintes fins:&nbsp;&nbsp;</p>
                <table border="1" cellspacing="0" cellpadding="1">
                        <tbody>
                                <tr>
                                        <td valign="top">
                                                <p class="align-left">Objetivos</p>
                                        </td>
                                        <td valign="top">
                                                <p class="align-left">Base Legal</p>
                                        </td>
                                </tr>
                                <tr>
                                        <td valign="top">
                                                <p class="align-left">Prestar servi&ccedil;os e fornecimento do
                                                        Aplicativo</p>
                                        </td>
                                        <td valign="top">
                                                <p>cumprimento de obriga&ccedil;&otilde;es legais e a
                                                        execu&ccedil;&atilde;o de um contrato ou procedimentos
                                                        preliminares</p>
                                        </td>
                                </tr>
                                <tr>
                                        <td valign="top">
                                                <p>Para melhorar nosso Aplicativo e nossos servi&ccedil;os de suporte e
                                                        manuten&ccedil;&atilde;o para nossos Clientes e para os
                                                        Usu&aacute;rios do Aplicativo</p>
                                        </td>
                                        <td valign="top">
                                                <p>cumprimento contratual, o exerc&iacute;cio regular de direitos em
                                                        processo judicial ou administrativo, o interesse leg&iacute;timo
                                                        e cumprimento de obriga&ccedil;&otilde;es legais, conforme
                                                        aplic&aacute;vel</p>
                                        </td>
                                </tr>
                                <tr>
                                        <td valign="top">
                                                <p class="align-left">Mensagens de contato e marketing</p>
                                        </td>
                                        <td valign="top">
                                                <p>interesse leg&iacute;timo em informar o titular dos Dados Pessoais
                                                        sobre seus temas de interesse, bem como sobre as atividades
                                                        oferecidas pelo Aplicativo&nbsp;</p>
                                        </td>
                                </tr>
                                <tr>
                                        <td valign="top">
                                                <p>Responder a solicita&ccedil;&otilde;es, reclama&ccedil;&otilde;es ou
                                                        prestar esclarecimentos aos titulares dos Dados Pessoais</p>
                                        </td>
                                        <td valign="top">
                                                <p>interesse leg&iacute;timo, cumprimento de obriga&ccedil;&otilde;es
                                                        legais e cumprimento contratual, conforme aplic&aacute;vel</p>
                                        </td>
                                </tr>
                                <tr>
                                        <td valign="top">
                                                <p>Realizar an&aacute;lises estat&iacute;sticas, garantindo, sempre que
                                                        poss&iacute;vel, a anonimiza&ccedil;&atilde;o dos Dados Pessoais
                                                </p>
                                                <p class="align-left">&nbsp;</p>
                                        </td>
                                        <td valign="top">
                                                <p class="align-left">interesse leg&iacute;timo da SVAAS e/ou de seu
                                                        Cliente
                                                </p>
                                        </td>
                                </tr>
                                <tr>
                                        <td valign="top">
                                                <p class="align-left">Medi&ccedil;&atilde;o do n&iacute;vel de
                                                        satisfa&ccedil;&atilde;o dos Usu&aacute;rios do Aplicativo&nbsp;
                                                </p>
                                        </td>
                                        <td valign="top">
                                                <p class="align-left">Consentimento por escrito</p>
                                        </td>
                                </tr>
                        </tbody>
                </table>
                <p class="align-left">O Usu&aacute;rio pode, a qualquer momento, optar por n&atilde;o receber&nbsp;
                        mensagens
                        e tipos de comunica&ccedil;&otilde;es relacionadas a marketing, escrevendo para n&oacute;s por
                        meio do e-mail <span class="underline blue">support@myalivius.com</span>&nbsp;</p>
                <p>Visando a melhoria cont&iacute;nua do Aplicativo, podemos ocasionalmente acionar determinados
                        atributos no Aplicativo controlados por nossos clientes, que coletam e armazenam
                        informa&ccedil;&otilde;es de uso no sistema, de modo a analisar a intera&ccedil;&atilde;o do
                        Usu&aacute;rio com as ferramentas e corrigir eventuais erros ou bugs.&nbsp;</p>
                <p>Utilizaremos os seus Dados Pessoais apenas para os fins para os quais foram coletados. Qualquer
                        altera&ccedil;&atilde;o na finalidade do tratamento dos Dados Pessoais ou Transfer&ecirc;ncia
                        Internacional de Dados Pessoais ser&aacute; informada ao respectivo titular destes, ainda que
                        para fins de cumprimento de obriga&ccedil;&otilde;es legais e/ou regulat&oacute;rias impostas
                        sobre a SVAAS ou para o cumprimento de seus interesses leg&iacute;timos.&nbsp;</p>
                <h4 class="align-left"><strong>8.</strong><strong> &nbsp;&nbsp;&nbsp;&nbsp;
                                Crian&ccedil;as</strong>&nbsp;
                        </h4>
                        <p>A SVAAS n&atilde;o pretende coletar Dados Pessoais de crian&ccedil;as e
                                adolescentes.<strong>&nbsp;</strong></p>
                        <h4>9. &nbsp;&nbsp;&nbsp;&nbsp; Compartilhamento de Dados Pessoais</h4>
                        <p>Comprometemo-nos a n&atilde;o divulgar, salvo disposi&ccedil;&atilde;o em contr&aacute;rio
                                nesta
                                Pol&iacute;tica de Privacidade, os</p>
                        <p>Dados Pessoais fornecidos por Voc&ecirc; a qualquer pessoa, a menos que seja previsto nos
                                seguintes
                                casos:&nbsp;</p>
                        <ul>
                                <li>Com as afiliadas da SVAAS e demais empresas do seu grupo, com parceiros de
                                        neg&oacute;cios,
                                        agentes e subcontratados localizados no Brasil ou no exterior, tudo em
                                        conson&acirc;ncia
                                        com a presente Pol&iacute;tica e a legisla&ccedil;&atilde;o aplic&aacute;vel
                                        para fins
                                        de cumprimento de obriga&ccedil;&otilde;es legais e regulat&oacute;rias impostas
                                        sobre a
                                        SVAAS e para o cumprimento de seu interesse leg&iacute;timo, quando atuando como
                                        controladora de dados.</li>
                                <li>Com funcion&aacute;rios e parceiros de neg&oacute;cios (terceiros e subcontratados)
                                        para o
                                        processamento dos Dados Pessoais do Usu&aacute;rio para auxiliar na
                                        execu&ccedil;&atilde;o do Aplicativo e fornecer nossos servi&ccedil;os,
                                        incluindo, por
                                        exemplo, provedores de hospedagem na web e provedores de TI (AWS, T Mobile
                                        etc.), para
                                        desenvolvimento de sistemas e suporte t&eacute;cnico, com auditores externos,
                                        consultores administrativos e jur&iacute;dicos para outros servi&ccedil;os.</li>
                                <li>Investigar, prevenir ou agir em rela&ccedil;&atilde;o a atividades ilegais, suspeita
                                        de
                                        fraude, situa&ccedil;&otilde;es relacionadas a potenciais amea&ccedil;as a
                                        qualquer
                                        seguran&ccedil;a individual.</li>
                                <li>Em caso de fus&atilde;o, aquisi&ccedil;&atilde;o ou transfer&ecirc;ncia de todos ou
                                        parte
                                        significativa dos ativos da SVAAS.</li>
                                <li>Mediante autoriza&ccedil;&atilde;o por escrito dos Usu&aacute;rios, conforme venha a
                                        ser
                                        exigido pela legisla&ccedil;&atilde;o aplic&aacute;vel.</li>
                                <li>Cumprimento de obriga&ccedil;&otilde;es legais, judiciais ou regulat&oacute;rias
                                        (conforme
                                        exigido por qualquer autoridade competente, incluindo a Ag&ecirc;ncia Nacional
                                        de
                                        Sa&uacute;de (&ldquo;ANVISA&rdquo;) ou Food Drugs e FDA;&nbsp;</li>
                                <li>Proteger os direitos, a seguran&ccedil;a e a propriedade da SVAAS na medida
                                        permitida por
                                        lei.&nbsp;</li>
                        </ul>
                        <p>Definimos responsabilidades e processos para avaliar e responder a solicita&ccedil;&otilde;es
                                de
                                dados de autoridades policiais ou Governamentais. As requisi&ccedil;&otilde;es
                                ser&atilde;o
                                feitas de acordo com as regras aplic&aacute;veis.&nbsp;</p>
                        <p>Os prestadores de servi&ccedil;os terceirizados s&atilde;o obrigados a usar medidas de
                                seguran&ccedil;a apropriadas para proteger a confidencialidade e a seguran&ccedil;a das
                                Informa&ccedil;&otilde;es Pessoais.&nbsp;</p>
                        <p>Na hip&oacute;tese de a SVAAS compartilhar Dados Pessoais com terceiros localizados fora do
                                Brasil,
                                dever&aacute; garantir que essa transfer&ecirc;ncia internacional de Dados ser&aacute;
                                realizada
                                de acordo com o disposto na legisla&ccedil;&atilde;o aplic&aacute;vel, de forma
                                adequada,
                                aderente &agrave;s suas finalidades e dentro dos limites do que for efetivamente
                                necess&aacute;rio para atender a esses requisitos.&nbsp;&nbsp;</p>
                        <p>A SVAAS tamb&eacute;m pode compartilhar seus Dados Pessoais agregados com os dados de outras
                                pessoas,
                                bem como os dados agregados que n&atilde;o incluem informa&ccedil;&otilde;es de
                                identifica&ccedil;&atilde;o pessoal ou s&atilde;o anonimizados.&nbsp;</p>
                        <p>Informamos que a SVAAS pode transferir os seus Dados Pessoais para jurisdi&ccedil;&otilde;es
                                estrangeiras com leis de prote&ccedil;&atilde;o de dados menos rigorosas do que as leis
                                do seu
                                pa&iacute;s.&nbsp;&nbsp;</p>
                        <p>N&atilde;o venderemos seus Dados Pessoais a terceiros.&nbsp;<strong>&nbsp;</strong></p>
                        <h4>10. Comunica&ccedil;&atilde;o</h4>
                        <p>A SVAAS e a Administradora se comunicar&atilde;o com Voc&ecirc; (por e-mail, servi&ccedil;o
                                postal,
                                correio, servi&ccedil;os de mensagens m&oacute;veis e ramais de telefone ou redes
                                sociais)
                                &agrave; medida em que voc&ecirc; concordar em receber essa comunica&ccedil;&atilde;o ou
                                quando
                                os requisitos operacionais ou regulamentares exigirem que o fa&ccedil;amos.&nbsp;</p>
                        <p>Podemos ainda nos comunicar com Voc&ecirc; por meio dos nossos&nbsp; fornecedores
                                terceirizados.&nbsp;&nbsp;</p>
                        <p>Voc&ecirc; ter&aacute; a op&ccedil;&atilde;o de cancelar a inscri&ccedil;&atilde;o para
                                receber essa
                                comunica&ccedil;&atilde;o por e-mail, enviando sua solicita&ccedil;&atilde;o a
                                n&oacute;s pelo
                                e-mail <span class="underline blue">support@myalivius.com.</span>&nbsp;
                                &nbsp;</p>
                        <h4>11. Links / Ofertas de Terceiros</h4>
                        <p>A SVAAS pode permitir que terceiros ofere&ccedil;am Servi&ccedil;os baseados em assinatura
                                e/ou
                                registro atrav&eacute;s do Aplicativo ou outras plataformas (por exemplo, WhatsApp,
                                Twitter
                                etc.). A SVAAS n&atilde;o ser&aacute; respons&aacute;vel por quaisquer
                                a&ccedil;&otilde;es ou
                                pol&iacute;ticas de tais terceiros e os Usu&aacute;rios ser&atilde;o solicitados a
                                verificar a
                                pol&iacute;tica de privacidade aplic&aacute;vel e familiarizar-se com os termos e
                                condi&ccedil;&otilde;es de uso de tais prestadores de servi&ccedil;os, ao fornecer
                                informa&ccedil;&otilde;es pessoalmente identific&aacute;veis.&nbsp;</p>
                        <p>A SVAAS n&atilde;o ser&aacute; respons&aacute;vel por qualquer varia&ccedil;&atilde;o nas
                                caracter&iacute;sticas/benef&iacute;cios oferecidos por tais terceiros e n&atilde;o
                                assumir&aacute;, em nenhum caso, garantia sobre a efici&ecirc;ncia ou efic&aacute;cia de
                                tais
                                ofertas.&nbsp;</p>
                        <h4>12. Reten&ccedil;&atilde;o dos Dados&nbsp;</h4>
                        <p>Usamos centros de dados e servi&ccedil;os de nuvem de terceiros para armazenar seus dados com
                                seguran&ccedil;a e minimizar a perda de dados. As salvaguardas de seguran&ccedil;a e
                                cl&aacute;usulas contratuais relevantes, conforme exigido pela legisla&ccedil;&atilde;o
                                aplic&aacute;vel em cada jurisdi&ccedil;&atilde;o, s&atilde;o cumpridas durante o
                                armazenamento
                                dos seus dados.&nbsp;</p>
                        <p>Manteremos apenas os Dados Pessoais e/ou Dados Pessoais Sens&iacute;veis necess&aacute;rios
                                para
                                gerenciar os servi&ccedil;os que prestamos e para os fins comerciais leg&iacute;timos e
                                indispens&aacute;veis, pelo tempo que for razoavelmente necess&aacute;rio ou permitido
                                para
                                cumprir a finalidade para a qual tais informa&ccedil;&otilde;es foram coletadas ou pelo
                                tempo
                                necess&aacute;rio para cumprir os regulamentos e a legisla&ccedil;&atilde;o
                                aplic&aacute;vel.&nbsp;</p>
                        <h4>13. Altera&ccedil;&otilde;es &agrave; Pol&iacute;tica de Privacidade</h4>
                        <p>Esta Pol&iacute;tica de Privacidade pode ser alterada, modificada, substitu&iacute;da por uma
                                nova
                                pol&iacute;tica, a qualquer momento, a nosso exclusivo crit&eacute;rio. A
                                Pol&iacute;tica de
                                Privacidade revisada/suas altera&ccedil;&otilde;es entrar&atilde;o em vigor a partir da
                                data
                                indicada.&nbsp;&nbsp;</p>
                        <p>Os Usu&aacute;rios s&atilde;o solicitados a verificar periodicamente os termos e
                                condi&ccedil;&otilde;es desta Pol&iacute;tica de Privacidade. A SVAAS n&atilde;o
                                ser&aacute;, em
                                nenhuma circunst&acirc;ncia, responsabilizados por tais lapsos por parte do
                                Usu&aacute;rio.&nbsp;&nbsp;</p>
                        <h4>14. Seguran&ccedil;a da Informa&ccedil;&atilde;o</h4>
                        <p>Tomamos precau&ccedil;&otilde;es razo&aacute;veis para proteger os dados processados no
                                Aplicativo
                                contra acesso n&atilde;o autorizado ou altera&ccedil;&atilde;o, divulga&ccedil;&atilde;o
                                ou
                                destrui&ccedil;&atilde;o n&atilde;o autorizada das informa&ccedil;&otilde;es que
                                possu&iacute;mos.&nbsp;<strong>&nbsp;</strong></p>
                        <ul>
                                <li>Criptografamos muitos de nossos Servi&ccedil;os usando os certificados SSL.&nbsp;
                                </li>
                                <li>Fornecemos autentica&ccedil;&atilde;o com base em senha para Usu&aacute;rios que
                                        fazem login
                                        em nosso Aplicativo.</li>
                                <li>Analisamos as nossas pr&aacute;ticas de coleta, armazenamento e processamento de
                                        informa&ccedil;&otilde;es, para nos protegermos contra o acesso n&atilde;o
                                        autorizado a
                                        sistemas.</li>
                                <li>Permitimos o acesso a informa&ccedil;&otilde;es pessoalmente identific&aacute;veis
                                        aos
                                        nossos funcion&aacute;rios, contratados e agentes, apenas com base na efetiva
                                        necessidade e para fins leg&iacute;timos, que est&atilde;o sujeitos a rigorosas
                                        obriga&ccedil;&otilde;es contratuais de confidencialidade para fins de
                                        processamento de
                                        dados.</li>
                                <li>Realizamos avalia&ccedil;&otilde;es de vulnerabilidade do nosso Aplicativo e
                                        sistemas de
                                        processamento de dados periodicamente.&nbsp;</li>
                        </ul>
                        <h4>15. Notifica&ccedil;&atilde;o e seguran&ccedil;a de viola&ccedil;&atilde;o de dados</h4>
                        <p>Como SVAAS Wellness tem a obriga&ccedil;&atilde;o de reportar incidentes de seguran&ccedil;a
                                de dados
                                pessoais &agrave; Autoridade Brasileira de Prote&ccedil;&atilde;o de Dados de acordo com
                                as leis
                                e regulamentos aplic&aacute;veis. Voc&ecirc; &eacute; obrigado a relatar qualquer
                                suspeita de
                                viola&ccedil;&atilde;o de dados ou incidente de seguran&ccedil;a dentro de um (1) dia
                                ap&oacute;s sua ocorr&ecirc;ncia &agrave; SVAAS Wellness Limited. A
                                notifica&ccedil;&atilde;o
                                oportuna nos d&aacute; tempo suficiente para investigar, implementar a&ccedil;&otilde;es
                                corretivas e cumprir quaisquer obriga&ccedil;&otilde;es regulat&oacute;rias de
                                relat&oacute;rios.<strong>&nbsp;</strong></p>
                        <h4>16. Aviso</h4>
                        <p>As informa&ccedil;&otilde;es, o conte&uacute;do e os materiais fornecidos no Aplicativo
                                s&atilde;o
                                "no estado em que se encontram" e a SVAAS n&atilde;o faz nenhuma
                                declara&ccedil;&atilde;o ou
                                garantia expressa ou impl&iacute;cita em rela&ccedil;&atilde;o a sua precis&atilde;o,
                                adequa&ccedil;&atilde;o ou integridade.&nbsp;</p>
                        <h4>17. Encarregado de Prote&ccedil;&atilde;o de Dados de Informa&ccedil;&otilde;es de Contato
                                ("DPO")
                        </h4>
                        <p>1.1. Caso voc&ecirc; precise de mais esclarecimentos sobre o conte&uacute;do desta
                                Pol&iacute;tica de
                                Privacidade ou sobre o uso de seus Dados Pessoais, entre em contato com o DPO da SVAAS
                                por meio
                                do e-mail <span class="underline blue">dataprivacy@svaaswellness.com</span>.&nbsp;&nbsp;
                        </p>
                        <h4>18. Direitos dos Titulares dos Dados</h4>
                        <p>Os titulares dos dados t&ecirc;m o direito de, a qualquer momento, exercer os direitos
                                concedidos
                                pela legisla&ccedil;&atilde;o aplic&aacute;vel, incluindo, solicitar o acesso, atualizar
                                e
                                retificar os seus dados, o direito de revogar o seu consentimento ou solicitar a
                                elimina&ccedil;&atilde;o dos seus dados, solicitar prova do consentimento dado,
                                solicitar a
                                portabilidade dos seus pr&oacute;prios dados para outro controlador e o direito de
                                apresentar
                                reivindica&ccedil;&otilde;es perante a autoridade de prote&ccedil;&atilde;o de
                                dados.&nbsp;</p>
                        <p>Caso o exerc&iacute;cio de quaisquer direitos do Usu&aacute;rio resulte em descumprimento das
                                obriga&ccedil;&otilde;es da SVAAS, nos termos das leis, c&oacute;digos de conduta,
                                posturas ou
                                regras aplic&aacute;veis, a SVAAS n&atilde;o atender&aacute; &agrave;
                                solicita&ccedil;&atilde;o
                                do Usu&aacute;rio. Em qualquer caso, &eacute; garantido ao Usu&aacute;rio o pedido de
                                bloqueio
                                dos seus Dados Pessoais para posterior tratamento.&nbsp;&nbsp;</p>
                        <h4>19. Procedimento para Acessar seus Dados Pessoais</h4>
                        <p>Voc&ecirc; tem acesso gratuito aos seus Dados Pessoais armazenados nas nossas bases de dados,
                                ao
                                tratamento a que est&atilde;o sujeitos e &agrave;s finalidades pretendidas, al&eacute;m
                                das
                                indicadas abaixo:&nbsp;</p>
                        <ul>
                                <li>As solicita&ccedil;&otilde;es devem ser enviadas por escrito e por e-mail ao
                                        DPO.&nbsp;</li>
                                <li>O pedido ser&aacute; analisado para verificar a sua identidade. Se a
                                        solicita&ccedil;&atilde;o for enviada por algu&eacute;m que n&atilde;o seja
                                        Voc&ecirc;
                                        (o titular dos dados) e a pessoa n&atilde;o puder fornecer evid&ecirc;ncias que
                                        estejam
                                        agindo legitimamente em seu nome, como seu cuidador ou respons&aacute;vel legal,
                                        a
                                        solicita&ccedil;&atilde;o ser&aacute; rejeitada.&nbsp;</li>
                        </ul>
                        <p>1.2. Voc&ecirc; pode apresentar uma reivindica&ccedil;&atilde;o quando considerar que as
                                informa&ccedil;&otilde;es inclu&iacute;das nas nossas bases de dados devem ser
                                retificadas,
                                atualizadas ou eliminadas, ou quando avisar previamente da alegada
                                viola&ccedil;&atilde;o dos
                                seus direitos, escrevendo para n&oacute;s por meio do e-mail <span
                                        class="underline blue">dataprivacy@svaaswellness.com</span>.&nbsp;
                                &nbsp;
                        </p>
                        <p>Garantimos que forneceremos a voc&ecirc; nossa primeira resposta &agrave; sua
                                reivindica&ccedil;&atilde;o no prazo de 30 (trinta) dias a partir da data de tal
                                reivindica&ccedil;&atilde;o e garantimos que encerraremos sua solicita&ccedil;&atilde;o
                                em ou
                                antes de 90 (noventa) dias a partir da data de recebimento de tal
                                reivindica&ccedil;&atilde;o.&nbsp;</p>
                        <p>Esta Pol&iacute;tica de Privacidade foi atualizada pela &uacute;ltima vez em October
                                28,&nbsp;
                                2024.&nbsp;</p>
                        <p>-------------------------------------------------------------x----------------------------------------------------------
                        </p>
        </div>
    )
}

export default PrivacyPolicy