import React from 'react'
import Header from './components/Header/Header'
import Landing from './components/Landing/Landing'
import Intro from './components/Intro/Intro'
import Hospital from './components/Hospitals/Hospital'
import Patient from './components/Patients/Patient'
import Footer from './components/Footer/Footer'
import PrivacyPolicyAliviusGo from './components/PrivacyPolicy/PrivacyPolicy-ALivius-Go'
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy.js'
import TermsAndConditionsAliviusGo from './components/TermsAndConditions/TermsAndConditions-ALivius-Go.js'
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions-ALivius.js'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import './App.css'
import { PrivacyPolicyEn } from './components/PrivacyPolicy/en/pp-en'
import PdfViewer from './components/PdfViewer/PdfViewer'

const App = () => {
  return (

    <BrowserRouter>
    <Routes>
      <Route path="/" element={<>
      <Header />
      <Landing />
      <Intro />
      <Hospital />
      <Patient />
      <Footer />

    </>}> </Route>

      <Route path="/privacy-policy" element={<PrivacyPolicy />} ></Route>
      <Route path="/en/privacy-policy" element={<PrivacyPolicyEn />} ></Route>
      <Route
          path='/terms-and-conditions'
          element={<TermsAndConditions/>}
        />
     <Route path="/privacy-policy-alivius-go" element={<PrivacyPolicyAliviusGo/>} ></Route>
     <Route
          path='/terms-and-conditions-alivio-go'
          element={<TermsAndConditionsAliviusGo />}
        />
     </Routes>
      </BrowserRouter>


  )
}

export default App